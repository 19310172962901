import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import DataTableBase from "../../components/DataTableBase";
import { CONFIG } from "../../config";
import axios from "axios";
import { getAuthHeader } from "../../auth";
import AddGroupsDialog from "../../components/AddGroupDialog";
import SkeletonTable from "../../components/SkeletonTable";
import Layout2 from "../../components/Layout2";

export default function Group() {
  document.title = CONFIG.site_title + " User Group Management";

  const [groups, setGroups] = React.useState([]);
  const [displayAddGroup, setDisplayAddGroup] = React.useState(false);

  async function getGroups() {
    const headers = await getAuthHeader();
    const apiUrl = CONFIG.API_URL + "/groups";
    const res = await axios.get(apiUrl, { headers: headers });

    if (res.data && res.data.length) {
      setGroups(res.data);
    }
  }

  const columns = [
    {
      name: "id",
      options: {
        display: false,
      },
    },
    {
      name: "name",
      options: {
        label: "Date",
        customBodyRender: (value, meta) => {
          return <Typography>{value}</Typography>;
        },
      },
    },
    {
      name: "members",
      options: {
        label: "Members",
        customBodyRender: (value, meta) => {
          return <Typography>{value?.length}</Typography>;
        },
      },
    },
    {
      name: "id",
      options: {
        label: "Options",
        customBodyRender: (value, meta) => {
          return (
            <Stack spacing={3}>
              <Button variant="outline" color="primary">
                View Members
              </Button>
              <Button variant="outline" color="primary">
                Delete
              </Button>
            </Stack>
          );
        },
      },
    },
  ];

  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      getGroups();
      return () => {
        mounted = false;
      };
    }
  }, []);

  return (
    <Layout2>
      <Card>
        <CardHeader
          title="User Groups"
          subheader="User groups allow you to create and share groups of trackers with other users."
          action={
            <Button
              variant="outlined"
              color="primary"
              disabled
              onClick={() => setDisplayAddGroup(true)}
            >
              Create A New Group
            </Button>
          }
        />
        <CardContent>
          {groups ? (
            <DataTableBase data={groups} columns={columns} />
          ) : (
            <SkeletonTable rows={10} columns={3} />
          )}
        </CardContent>
      </Card>
      <AddGroupsDialog open={displayAddGroup} onClose={setDisplayAddGroup} />
    </Layout2>
  );
}
