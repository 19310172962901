// container for payment success page

import Layout2 from "../../components/Layout2";

export default function BillingSuccess() {
  return (
    <Layout2>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>Payment Successfull</h1>
            <p>
              Thank you for your payment. Your payment has been successfully
              processed.
            </p>
          </div>
        </div>
      </div>
    </Layout2>
  );
}
