import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getAuthHeader } from "../../auth";
import { CONFIG } from "../../config";
import axios from "axios";
import moment from "moment";

import { Container, Grid } from "@mui/material";
import Layout from "../../components/Layout";
import MapPanel from "../../components/MapPanel";
import TrackerTimelineCard from "../../components/TrackerTimelineCard";
import AssetInfoCard from "../../components/AssetInfoCard";
import AssetAssignedTrackersCard from "../../components/AssetAssignedTrackersCard";
import AssetHistoryCard from "../../components/AssetHistoryCard";

const start = moment.utc().startOf("day").format();
const end = moment.utc().endOf("day").format();

export default function Asset(props) {
  const [assetHistory, setAssetHistory] = useState([]);
  const [assets, setAssets] = useState([]);
  const [trackers, setTrackers] = useState([]);
  const [polylines, setPolyLines] = useState({});
  const [initialLatLng, setInitialLatLng] = useState([0, 0]);
  const [assetStartDate, setAssetStartDate] = useState(start);
  const [assetEndDate, setAssetEndDate] = useState(end);
  const [current, setCurrent] = useState(0);
  const [markerPosition, setMarkerPosition] = useState({ lat: 0, lng: 0 });
  const [selectedHotspots, setSelectedHotspots] = useState([]);
  const [hotspots, setHotspots] = useState([]);
  const [intervalState, setIntervalState] = useState(false);
  const [user, setUser] = useState();
  const [assignedTrackers, setAssignedTrackers] = useState([]);

  let params = useParams();

  async function getUser() {
    let headers = await getAuthHeader();
    headers["content-type"] = "application/json";
    const apiUrl = CONFIG.API_URL + "/user";

    const user = await axios.get(apiUrl, { headers: headers });
    setUser(user.data);
  }

  function updateMap(value) {
    // update main marker and map center
    setCurrent(value);
    setSelectedHotspots([]);
    setMarkerPosition({
      lat: assetHistory[value].latitude,
      lng: assetHistory[value].longitude,
    });
    assetHistory[value].received_by.map((hotspot) => {
      const hotspotSelected = {
        id: hotspot.id,
        rssi: hotspot.rssi,
      };
      setSelectedHotspots((previous) => [...previous, hotspotSelected]);
      return null;
    });
  }

  async function getAsset() {
    const headers = await getAuthHeader();
    const apiUrl = CONFIG.API_URL + "/assets/" + params.id;
    const res = await axios.get(apiUrl, { headers: headers });

    setAssets([res.data]);

    setInitialLatLng([
      res.data?.last_location?.latitude || 0,
      res.data?.last_location?.longitude || 0,
    ]);

    document.title = res.data.name + " Asset Management - " + CONFIG.site_title;
  }

  async function fetchHotspots() {
    const headers = await getAuthHeader();

    const apiURLLocations =
      CONFIG.API_URL +
      "/assets/" +
      params.id +
      "/locations?start=" +
      moment(assetStartDate).toISOString() +
      "&end=" +
      moment(assetEndDate).toISOString();

    //get device location history
    const res2 = await axios.get(apiURLLocations, { headers: headers });

    setAssetHistory(res2.data.sort((a, b) => a.time - b.time).reverse());

    //map location checkin hotspots to real locations
    var hotspotIDs = [];
    res2.data.map((location) => {
      location.received_by.map((hotspot) => {
        hotspotIDs.push(hotspot.id);
        return null;
      });
      return null;
    });
    hotspotIDs = [...new Set(hotspotIDs)];

    const hotspotData = [];
    hotspotIDs.map(async (hotspotID) => {
      const res = await axios.get(
        "https://ugxlyxnlrg9udfdyzwnrvghlu2vydmvycg.blockjoy.com/v1/hotspots/" +
          hotspotID
      );

      const hotspot = {
        id: hotspotID,
        name: res.data.data.name,
        lat: res.data.data.lat,
        lng: res.data.data.lng,
      };

      hotspotData.push(hotspot);
    });

    setHotspots(hotspotData);
  }

  async function getUplinks(refreshMarker) {
    const headers = await getAuthHeader();

    const apiURLLocations =
      CONFIG.API_URL +
      "/assets/" +
      params.id +
      "/locations?start=" +
      moment(assetStartDate).toISOString() +
      "&end=" +
      moment(assetEndDate).toISOString();

    //get device location history
    const res2 = await axios.get(apiURLLocations, { headers: headers });

    setAssetHistory(res2.data.sort((a, b) => a.time - b.time).reverse());

    if (refreshMarker) {
      if (res2?.data[0]?.received_by && res2?.data[0]?.received_by.length > 0) {
        const firstSelectedHotspots = res2?.data[0]?.received_by.map(
          (hotspot) => {
            return {
              id: hotspot.id,
              rssi: hotspot.rssi,
            };
          }
        );

        setSelectedHotspots(firstSelectedHotspots);
      } else {
        setSelectedHotspots([]);
      }
    }

    const pollylineArray = [];
    //return location history lat long for polyline
    res2.data.map((location) => {
      if (location.longitude !== 0 && location.latitude !== 0) {
        pollylineArray.push([location.latitude, location.longitude]);
      }
      return null;
    });

    setPolyLines(pollylineArray);

    //fetch assigned trackers
    const apiURLTrackers =
      CONFIG.API_URL + "/assets/" + params.id + "/assignments";
    const trackers = await axios.get(apiURLTrackers, { headers: headers });
    setTrackers(trackers.data);

    if (refreshMarker) {
      setMarkerPosition({
        lat: res2.data[res2.data.length - 1].latitude,
        lng: res2.data[res2.data.length - 1].longitude,
      });
      setCurrent(res2.data.length - 1);
    }
  }

  function restartPlayback() {
    setCurrent(0);
    if (intervalState === false) {
      startPlayback();
    }
  }

  function startPlayback(reset) {
    if (intervalState === false) {
      setIntervalState(
        setInterval(() => {
          setCurrent((current) => current + 1);
        }, 1000)
      );
    }
  }

  function stopPlayback() {
    clearInterval(intervalState);
    setIntervalState(false);
  }

  async function fetchAssignedTrackers() {
    const headers = await getAuthHeader();
    const apiURLTrackers =
      CONFIG.API_URL + "/assets/" + params.id + "/assignments";

    const trackers = await axios.get(apiURLTrackers, { headers: headers });

    //fetch device id's for list with name
    const deviceIDs = [];
    trackers.data.map((device) => {
      deviceIDs.push({
        id: device.device,
        assignment_id: device.id,
      });
      return null;
    });

    const deviceData = [];
    deviceIDs.map(async (deviceID) => {
      const res = await axios.get(CONFIG.API_URL + "/devices/" + deviceID.id, {
        headers: headers,
      });

      const device = {
        id: deviceID.id,
        assignment_id: deviceID.assignment_id,
        name: res.data.name,
      };

      deviceData.push(device);
    });

    setAssignedTrackers(deviceData);
  }

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (current) {
        if (current >= assetHistory.length - 1) {
          setCurrent(assetHistory.length - 1);
          stopPlayback();
          return;
        }
        if (current < assetHistory.length - 1) {
          // update main marker and map center
          setMarkerPosition({
            lat: assetHistory[current]?.latitude,
            lng: assetHistory[current]?.longitude,
          });
          setSelectedHotspots(
            assetHistory[current]?.received_by?.map((hotspot) => {
              return {
                id: hotspot.id,
                rssi: hotspot.rssi,
              };
            })
          );
        }
        if (current === assetHistory.length - 1 && intervalState !== false) {
          stopPlayback();
        }
      }
    }
    return () => (mounted = false);
    // eslint-disable-next-line
  }, [current]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getUser();
      getAsset();

      return () => {
        mounted = false;
      };
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getUplinks(true);
      fetchHotspots();
      fetchAssignedTrackers();

      const interval = setInterval(() => {
        getUplinks(false);
      }, 10000);
      return () => {
        mounted = false;
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line
  }, [assetStartDate, assetEndDate]);

  return (
    <Layout selectedTracker={params.id}>
      <MapPanel
        trackers={assets}
        deviceTracking
        polylines={polylines}
        trackerHistory={assetHistory}
        initialLatLng={initialLatLng || { lat: 0, lng: 0 }}
        trackerStartDate={assetStartDate}
        trackerEndDate={assetEndDate}
        setAssetstartDate={setAssetStartDate}
        setTrackerEndDate={setAssetEndDate}
        mode={props.mode}
        markerPosition={markerPosition}
        setMarkerPosition={setMarkerPosition}
        selectedHotspots={selectedHotspots}
        hotspots={hotspots}
        current={current}
        updateMap={updateMap}
        heliumEnthusiastMode={user?.helium_enthusiast_mode || false}
      />

      <Container maxWidth="false" className="my-4">
        <Grid container spacing={3} className="trackerUX">
          <Grid item xs={12} md={4}>
            <AssetInfoCard asset={assets[0]} getAsset={getAsset} />
          </Grid>
          <Grid item xs={12} md={8}>
            <TrackerTimelineCard
              trackerHistory={assetHistory}
              updateMap={updateMap}
              current={current}
              startPlayback={startPlayback}
              stopPlayback={stopPlayback}
              setCurrent={setCurrent}
              restartPlayback={restartPlayback}
              heliumEnthusiastMode={user?.helium_enthusiast_mode || false}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <AssetAssignedTrackersCard assignedTrackers={assignedTrackers} />
          </Grid>
          <Grid item xs={12} md={8}>
            <AssetHistoryCard
              trackerHistory={assetHistory}
              trackerStartDate={assetStartDate}
              trackerEndDate={assetEndDate}
              trackerName={assets[0]?.name}
              trackers={assets}
            />
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
}
