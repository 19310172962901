import React, { useEffect, useState } from "react";
import DataTableBase from "../../components/DataTableBase";
import {
  Grid,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import SkeletonTable from "../SkeletonTable";
import { getAuthHeader } from "../../auth";
import { CONFIG } from "../../config";
import axios from "axios";
import { PlusCircle } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import formatDate from "../../Functions/formatDate";

export default function TrackerListTable(props) {
  const [trackers, setTrackers] = useState([]);
  const navigate = useNavigate();

  const trackerColumns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({ style: { maxWidth: "100px" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography
              variant="body1"
              align="center"
              component={"a"}
              sx={{
                cursor: "pointer",
                color: "primary.main",
                textDecoration: "none",
                ":hover": {
                  textDecoration: "underline",
                  color: "primary.dark",
                },
              }}
              onClick={() => navigate("/tracker/" + tableMeta.rowData[0])}
            >
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "last_location",
      label: "Last Seen",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { maxWidth: "100px" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value && value.time) {
            return (
              <Typography variant="body1">{formatDate(value.time)}</Typography>
            );
          } else {
            return <Typography variant="body1">N/A</Typography>;
          }
        },
      },
    },
    {
      name: "last_location",
      label: "Last Location",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { maxWidth: "350px" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value && value.geocode) {
            return <Typography>{value.geocode}</Typography>;
          } else {
            return <Typography>N/A</Typography>;
          }
        },
      },
    },
    {
      name: "last_location",
      label: "Battery",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value && value.battery) {
            //draw a progress bar with the value and color it red if below 20% and yellow if below 50% and green if above 50%
            return (
              <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={12} md={6}>
                  <LinearProgress
                    variant="determinate"
                    value={value.battery}
                    max={100}
                    min={0}
                    sx={{ height: 6, borderRadius: "12px" }}
                    color={
                      value.battery < 20
                        ? "error"
                        : value.battery < 50
                        ? "warning"
                        : "success"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body2" align="center">
                    {value.battery}%
                  </Typography>
                </Grid>
              </Grid>
            );
          } else {
            return <Typography>N/A</Typography>;
          }
        },
      },
    },
  ];

  async function getDevices() {
    try {
      const headers = await getAuthHeader();
      const apiUrl = CONFIG.API_URL + "/devices";
      const res = await axios.get(apiUrl, { headers: headers });

      setTrackers(res.data);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getDevices();

    const interval = setInterval(() => {
      getDevices();
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  return (
    <DataTableBase
      title={"Trackers"}
      data={trackers}
      columns={trackerColumns}
      rowsPerPage={10}
      search={false}
      download={false}
      sort={true}
      sortOrder={"name"}
      sortDirection={"asc"}
      customToolbar={
        <Tooltip title="Add Tracker">
          <IconButton
            aria-label="Add Tracker"
            onClick={() => navigate("/tracker/add")}
          >
            <PlusCircle />
          </IconButton>
        </Tooltip>
      }
    />
  );
}
