import React, { useState } from "react";
import {
  Box,
  Card,
  IconButton,
  Slider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import ReplayIcon from "@mui/icons-material/Replay";
import PinDropIcon from "@mui/icons-material/PinDrop";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
//router icon
import RouterIcon from "@mui/icons-material/Router";

import "moment-timezone";
import formatDate from "../../Functions/formatDate";

export default function HistoryPlaybackControl(props) {
  const theme = useTheme();
  //clean geocode address into something more readable, split on commas and take the first part
  function cleanAddress(address) {
    try {
      // split on commas
      let split = address.split(",");

      let addressCleaned =
        split[0] +
        " " +
        split[1] +
        ", " +
        split[2] +
        ", " +
        split[3] +
        ", " +
        split[4];

      if (split[6] !== undefined) {
        addressCleaned = addressCleaned + ", " + split[6];
      }

      return addressCleaned;
    } catch (e) {
      return "No geocode available";
    }
  }

  const [intervalState, setIntervalState] = useState(false);

  function restartPlayback() {
    props.setCurrent(0);
    if (intervalState === false) {
      startPlayback();
    }
  }

  function startPlayback() {
    if (props.current === props.trackerHistory.length - 1) {
      props.setCurrent(0);
    }
    if (intervalState === false) {
      setIntervalState(
        setInterval(() => {
          props.setCurrent((current) => current + 1);
        }, 1000)
      );
    }
  }

  function stopPlayback() {
    clearInterval(intervalState);
    setIntervalState(false);
  }

  if (props.trackerHistory && props.trackerHistory.length > 0) {
    return (
      <Box sx={{ mb: 3 }}>
        <Card
          className="history-playback-control"
          fullWidth
          //set background color based on theme mode
          sx={{
            backgroundColor: theme.palette.mode === "dark" ? "#1e1e1e" : "#fff",
          }}
        >
          <Typography
            className="text-center text-nowrap"
            sx={{ overflow: "hidden" }}
          >
            <PinDropIcon sx={{ fontSize: 20 }} />{" "}
            {cleanAddress(props?.trackerHistory[props.current]?.geocode)}
          </Typography>
          <Typography className="text-center">
            <AccessTimeIcon sx={{ fontSize: 20 }} />{" "}
            {formatDate(props?.trackerHistory[props?.current]?.time)}{" "}
            {props.heliumEnthusiastMode && <RouterIcon sx={{ fontSize: 20 }} />}{" "}
            {props.heliumEnthusiastMode &&
              props?.trackerHistory[props?.current]?.received_by?.length + " "}
            {props?.heliumEnthusiastMode && " Hotspot"}
            {props?.heliumEnthusiastMode &&
            props?.trackerHistory[props?.current]?.received_by?.length > 1
              ? "s"
              : ""}
          </Typography>
          <Slider
            defaultValue={0}
            aria-label="Default"
            max={props.trackerHistory ? props.trackerHistory.length - 1 : 0}
            step={1}
            onChange={(e, value) => {
              props.setCurrent(value);
            }}
            value={props.current}
            className="slider"
            marks={props.trackerHistory.map((item, index) => {
              return {
                value: index,
              };
            })}
            sx={{
              // set color based on theme mode
              color: theme.palette.success.main,
            }}
          />
          <Stack
            direction="row"
            spacing={5}
            justifyContent="center"
            alignItems="stretch"
          >
            <IconButton
              color="primary"
              aria-label="Play"
              component="label"
              onClick={() => startPlayback(false)}
              className="play-button btn-history-playback-control"
              sx={{
                // set color based on theme mode
                color: theme.palette.success.main,
              }}
            >
              <PlayArrowIcon size={24} />
            </IconButton>
            <IconButton
              color="primary"
              aria-label="Stop"
              component="label"
              onClick={() => stopPlayback()}
              className="stop-button btn-history-playback-control"
              sx={{
                // set color based on theme mode
                color: theme.palette.success.main,
              }}
            >
              <StopIcon size={24} />
            </IconButton>
            <IconButton
              color="primary"
              aria-label="restart"
              component="label"
              className="restart-button btn-history-playback-control"
              onClick={() => {
                restartPlayback();
              }}
              sx={{
                // set color based on theme mode
                color: theme.palette.success.main,
              }}
            >
              <ReplayIcon size={24} />
            </IconButton>
          </Stack>
        </Card>
      </Box>
    );
  } else {
    return <div></div>;
  }
}
