import React from "react";
import DataTableBase from "../../components/DataTableBase";
import Typography from "@mui/material/Typography";
import { Grid, LinearProgress } from "@mui/material";
import formatDate from "../../Functions/formatDate";

export default function TrackerHistoryCard(props) {
  const columns = [
    {
      name: "id",
      options: {
        label: "ID",
        display: false,
        filter: false,
      },
    },
    {
      name: "time",
      options: {
        label: "Date",
        filter: false,
        customBodyRender: (value, meta) => {
          return formatDate(value);
        },
      },
    },
    {
      name: "geocode",
      options: {
        label: "Last Location",
        filter: false,
        customBodyRender: (value, meta) => {
          return <Typography>{value}</Typography>;
        },
      },
    },
    {
      name: "battery",
      options: {
        label: "Battery",
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          //draw a progress bar with the value and color it red if below 20% and yellow if below 50% and green if above 50%
          return (
            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              spacing={1}
            >
              <Grid item xs={12} md={6}>
                <LinearProgress
                  variant="determinate"
                  value={value}
                  max={100}
                  min={0}
                  sx={{ height: 6, borderRadius: "12px" }}
                  color={
                    value < 20 ? "error" : value < 50 ? "warning" : "success"
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body2" align="center">
                  {value}%
                </Typography>
              </Grid>
            </Grid>
          );
        },
      },
    },
    //latitude, longitude
    {
      name: "latitude",
      options: {
        label: "Latitude",
        filter: false,
        display: false,
      },
    },
    {
      name: "longitude",
      options: {
        label: "Longitude",
        filter: false,
        display: false,
      },
    },
  ];

  return (
    <DataTableBase
      data={
        props.trackerHistory && props.trackerHistory.length
          ? props.trackerHistory
          : []
      }
      columns={columns}
      sortOrder="time"
      rowsPerPage={5}
      title="Tracker History"
      download={true}
    />
  );
}
