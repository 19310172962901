import mapboxgl from "mapbox-gl";
import getCleanedLine from "./getCleanedLine";
import { minMaxLngLat } from "./minMaxLngLat";

//mapbox access token
mapboxgl.accessToken =
  "pk.eyJ1IjoiYmZnbmVpbCIsImEiOiJjbGR2azExeW4wZ28yM29tZm4xZjJwdHFxIn0.Mdv--BkZwKSw-pdTRMQdPg";

const dashArraySequence = [
  [0, 4, 3],
  [0.5, 4, 2.5],
  [1, 4, 2],
  [1.5, 4, 1.5],
  [2, 4, 1],
  [2.5, 4, 0.5],
  [3, 4, 0],
  [0, 0.5, 3, 3.5],
  [0, 1, 3, 3],
  [0, 1.5, 3, 2.5],
  [0, 2, 3, 2],
  [0, 2.5, 3, 1.5],
  [0, 3, 3, 1],
  [0, 3.5, 3, 0.5],
];

//add polyline for history
export default async function addPolyline(
  history,
  mapRef,
  trackerMatchingSetting
) {
  if (mapRef?.current) return;
  //create coords array for history features
  let coords = history.map((item) => {
    return minMaxLngLat([item.longitude, item.latitude]);
  });

  let polyLineGeometry = coords;

  //if local storage mapMatching is true, run map matching
  if (trackerMatchingSetting === "true") {
    polyLineGeometry = await getCleanedLine(coords);
  }

  if (!mapRef.getSource("history")) {
    // add a source for the tag's location history
    mapRef.addSource("history", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "LineString",
              coordinates: polyLineGeometry,
            },
          },
        ],
      },
    });

    // add a line layer without line-dasharray defined to fill the gaps in the dashed line
    mapRef.addLayer({
      type: "line",
      source: "history",
      id: "line-background",
      paint: {
        "line-color": "#004d40",
        "line-width": 3,
        "line-opacity": 1,
      },
    });

    // add a line layer with line-dasharray set to the first value in dashArraySequence
    mapRef.addLayer({
      type: "line",
      source: "history",
      id: "line-dashed",
      paint: {
        "line-color": "#00D09F",
        "line-width": 3,
        "line-dasharray": [0, 4, 3],
      },
    });

    let step = 0;

    function animateDashArray(timestamp) {
      try {
        // Update line-dasharray using the next value in dashArraySequence. The
        // divisor in the expression `timestamp / 50` controls the animation speed.
        const newStep = parseInt((timestamp / 50) % dashArraySequence.length);

        if (newStep !== step) {
          mapRef.setPaintProperty(
            "line-dashed",
            "line-dasharray",
            dashArraySequence[step]
          );
          step = newStep;
        }

        // Request the next frame of the animation.
        requestAnimationFrame(animateDashArray);
      } catch (err) {
        console.log(err);
      }
    }

    try {
      // Start the animation.
      animateDashArray(0);
    } catch (err) {
      console.log(err);
    }
  } else {
    //update source
    mapRef.getSource("history").setData({
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: polyLineGeometry,
          },
        },
      ],
    });
  }
}
