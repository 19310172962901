import { Button, Card, CardContent, CardHeader } from "@mui/material";
import DataTableBase from "../DataTableBase";
import SkeletonTable from "../SkeletonTable";

export default function AssetAssignedTrackersCard(props) {
  const columns = [
    {
      name: "id",
      options: {
        label: "ID",
        display: false,
      },
    },
    {
      name: "name",
      options: {
        label: "Name",
      },
    },
    {
      name: "Options",
      options: {
        label: "Options",
        customBodyRender: (value, meta) => {
          return (
            <Button
              variant="outlined"
              onClick={() => {
                //redirect to tracker/id
                window.location.href = `/tracker/${meta.rowData[0]}`;
              }}
            >
              View
            </Button>
          );
        },
      },
    },
    {
      name: "assignment_id",
      options: {
        label: "Assignment ID",
        display: false,
      },
    },
  ];

  return (
    <Card>
      <CardHeader title="Assigned Trackers" />
      <CardContent>
        {props.assignedTrackers ? (
          <DataTableBase
            data={props.assignedTrackers.sort((a, b) => {
              return a.name.localeCompare(b.name);
            })}
            columns={columns}
            checkEmpty={true}
            sortOrder="name"
            sortDirection="asc"
          />
        ) : (
          <SkeletonTable rows={10} columns={4} />
        )}
      </CardContent>
    </Card>
  );
}
