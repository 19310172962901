import React from "react";
import Layout from "../../components/Layout";
import { CONFIG } from "../../config";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid,
} from "@mui/material";
import QrReader from "react-qr-scanner";
import { getAuthHeader } from "../../auth";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";

export default function AddTracker() {
  const [fail, setFail] = React.useState(false);

  function handleScan(data) {
    if (data?.text) {
      claimDevice(data);
    }
  }

  async function claimDevice(data) {
    try {
      let headers = await getAuthHeader();
      const apiUrl = CONFIG.API_URL + "/devices/claim";
      await axios.post(apiUrl, data.text, {
        headers: headers,
      });
      alert("Device onboarded!");
      window.location.href = "/tracker";
    } catch (e) {
      console.log(e);
      setFail(true);
    }
  }

  function handleError(err) {
    console.error(err);
  }

  document.title = CONFIG.site_title + " Add a Tracker";

  return (
    <Box sx={{ py: 3 }}>
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader
              title="Add a Tracker"
              subheader="Please scan the QR code on your tracker or the leaflet that
                  came with it to add a tracker."
            />
            <CardContent>
              {fail && (
                <Alert severity="error" sx={{ marginBottom: 4 }}>
                  QR code not found. Please get in touch with an agent via
                  livechat (icon bottom right) to add a tracker.
                </Alert>
              )}
              <QrReader
                delay="0"
                style={{ width: "100%", height: "100%" }}
                onError={handleError}
                onScan={handleScan}
              />
            </CardContent>
            <CardActions>
              <Button
                variant="outlined"
                color="error"
                onClick={() => {
                  window.location.href = "/tracker";
                }}
                startIcon={<CloseIcon />}
              >
                Cancel
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
