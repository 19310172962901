import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Slider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import ReplayIcon from "@mui/icons-material/Replay";
import PinDropIcon from "@mui/icons-material/PinDrop";

export default function TrackerTimelineCard(props) {
  const [cardTitle, setCardTitle] = useState("Timeline");
  const [trackerHistory, setTrackerHistory] = useState([]);
  const [intervalState, setIntervalState] = useState(false);

  const theme = useTheme();

  function restartPlayback() {
    props.setCurrent(0);
    if (intervalState === false) {
      startPlayback();
    }
  }

  function startPlayback() {
    if (props.current === trackerHistory.length - 1) {
      props.setCurrent(0);
    }
    if (intervalState === false) {
      setIntervalState(
        setInterval(() => {
          props.setCurrent((current) => current + 1);
        }, 1000)
      );
    }
  }

  function stopPlayback() {
    clearInterval(intervalState);
    setIntervalState(false);
  }

  function formatDate(dateUTC) {
    var date = moment.utc(dateUTC).format();
    return moment.utc(date).local().format("h:mma MMM Do");
  }

  function calculateDistance(lat1, lon1, lat2, lon2) {
    if (lat1 === lat2 && lon1 === lon2) {
      return 0;
    } else {
      var radlat1 = (Math.PI * lat1) / 180;
      var radlat2 = (Math.PI * lat2) / 180;
      var theta = lon1 - lon2;
      var radtheta = (Math.PI * theta) / 180;
      var dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      dist = dist * 1.609344;
      return dist;
    }
  }

  //map deviceHistory prop and calculate distance between each GPS coordinate
  function calculateTotalDistance() {
    let totalDistance = 0;
    if (trackerHistory && trackerHistory.length > 0) {
      trackerHistory.map((location, index) => {
        if (index > 0) {
          totalDistance += calculateDistance(
            location.latitude,
            location.longitude,
            trackerHistory[index - 1].latitude,
            trackerHistory[index - 1].longitude
          );
          return totalDistance;
        }

        return 0;
      });
    }

    //if user preference is set to miles, convert to miles
    if (props.userMeasurementUnit === "imperial") {
      totalDistance = totalDistance * 0.621371;
    }

    return totalDistance.toFixed(2);
  }

  useEffect(() => {
    if (props.trackerHistory && props.trackerHistory.length > 0) {
      try {
        setTrackerHistory(props.trackerHistory);
      } catch (e) {
        console.log(e);
      }
    }
  }, [props.trackerHistory]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (props.current >= trackerHistory.length - 1) {
        props.setCurrent(trackerHistory.length - 1);
        stopPlayback();
        return;
      }

      if (trackerHistory && trackerHistory.length > 0) {
        setCardTitle(
          "Timeline - " + formatDate(trackerHistory[props?.current || 0]?.time)
        );
      } else {
        setCardTitle("Timeline");
      }
    }
    return () => (mounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.current, props.trackerHistory]);

  return (
    <>
      <Card
        className="tracker-timeline-control"
        sx={{
          backgroundColor:
            theme.palette.mode === "dark" ? "#212121" : "#FFFFFF",
        }}
      >
        <CardHeader
          title={cardTitle}
          subheader={
            "Total Distance Travelled: " +
            calculateTotalDistance() +
            (props.userMeasurementUnit === "metric" ? " km" : " miles") +
            (props.heliumEnthusiastMode
              ? " - Heard By " + (props?.hotspots?.length || 0) + " Hotspots"
              : "")
          }
        />
        <CardContent>
          {trackerHistory && trackerHistory.length > 0 ? (
            <>
              <Typography className="mb-3">
                <PinDropIcon sx={{ fontSize: 20 }} />{" "}
                {trackerHistory[props.current]?.geocode}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                {props.heliumEnthusiastMode && (
                  <Typography className="mb-3">
                    Heard By:{" "}
                    {trackerHistory[props?.current]?.received_by?.length}{" "}
                    Hotspots(s)
                  </Typography>
                )}

                {trackerHistory && <Typography className="mb-3"></Typography>}
              </Stack>
              <Slider
                defaultValue={0}
                aria-label="Default"
                max={trackerHistory ? trackerHistory.length - 1 : 0}
                step={1}
                onChange={(e, value) => {
                  props.setCurrent(value);
                }}
                value={props.current}
                className="slider"
                marks={trackerHistory.map((item, index) => {
                  return {
                    value: index,
                  };
                })}
              />
              <Stack
                direction="row"
                spacing={5}
                justifyContent="center"
                alignItems="stretch"
              >
                <IconButton
                  color="primary"
                  aria-label="Play"
                  component="label"
                  onClick={() => startPlayback(false)}
                >
                  <PlayArrowIcon size={24} />
                </IconButton>
                <IconButton
                  color="primary"
                  aria-label="Stop"
                  component="label"
                  onClick={() => stopPlayback()}
                >
                  <StopIcon size={24} />
                </IconButton>
                <IconButton
                  color="primary"
                  aria-label="restart"
                  component="label"
                  onClick={() => {
                    restartPlayback();
                  }}
                >
                  <ReplayIcon size={24} />
                </IconButton>
              </Stack>
            </>
          ) : (
            <p>No history available</p>
          )}
        </CardContent>
      </Card>
    </>
  );
}
