import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { CardHeader, Skeleton } from "@mui/material";
import PinDropIcon from "@mui/icons-material/PinDrop";
import BatteryCharging90Icon from "@mui/icons-material/BatteryCharging90";
import { useNavigate } from "react-router-dom";

export default function AssetInfoCard(props) {
  let navigate = useNavigate();

  function formatDate(dateUTC) {
    var date = moment.utc(dateUTC).format();
    return moment.utc(date).local().format("h:mma MMM Do YYYY");
  }

  return (
    <>
      <Card
        onClick={() => {
          navigate("/asset/" + props.asset.id);
        }}
        className="tracker-info-card"
      >
        <CardHeader
          title={props.asset?.name || <Skeleton />}
          titleTypographyProps={{ variant: "h5", className: "cardHeader" }}
          subheader={formatDate(props.asset?.last_location?.time)}
          avatar={
            <img
              src={props.asset?.image_url || "/tracker-placeholder.jpeg"}
              alt={props.asset?.name}
              className="tracker-icon"
              width="40"
              height="40"
            />
          }
          action={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                fontSize: 20,
                minHeight: 50,
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <BatteryCharging90Icon sx={{ fontSize: 20 }} />
              {Math.round(props.asset?.last_location?.battery || 0)}%
            </div>
          }
        />

        <CardContent>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              fontSize: 20,
              marginTop: 15,
            }}
          >
            <Typography className="mb-3">
              <PinDropIcon sx={{ fontSize: 20 }} />
              {props.asset?.last_location?.geocode || "No Location Yet"}
            </Typography>
          </div>
        </CardContent>
      </Card>
    </>
  );
}
