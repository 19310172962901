import axios from "axios";
import { getAuthHeader } from "../auth";
import { CONFIG } from "../config";
import checkTrial from "./checkTrial";

//check trial and redirect if expired
export default async function checkTrialSub(device_id) {
  const authHeader = await getAuthHeader();
  const response = await axios.get(CONFIG.API_URL + "/user", {
    headers: authHeader,
  });
  const userDB = response.data;

  if (userDB.whitelabel?.name) {
    if (userDB.whitelabel.custom_billing !== true) {
      //check trial and redirect
      const trialExpired = await checkTrial(device_id);
      if (trialExpired) {
        window.location.href = "/tracker/" + device_id + "/billing";
        return false;
      }
    }
  } else {
    //check trial and redirect
    const trialExpired = await checkTrial(device_id);
    if (trialExpired) {
      window.location.href = "/tracker/" + device_id + "/billing";
      return false;
    }
  }

  return true;
}
