import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Container,
  FormControl,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { getAuthHeader } from "../../auth";
import { CONFIG } from "../../config";
import axios from "axios";
import {
  AccountCircle,
  DarkMode,
  LightMode,
  Logout,
  People,
  WarningAmber,
} from "@mui/icons-material";
import { Box } from "@mui/material";

export default function Layout(props) {
  const navigate = useNavigate();
  const [trackers, setTrackers] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuOpen, setMenuOpen] = React.useState(false);

  useEffect(() => {
    getDevices();
  }, []);

  async function getDevices() {
    const headers = await getAuthHeader();
    const apiUrl = CONFIG.API_URL + "/devices";
    const res = await axios.get(apiUrl, { headers: headers });

    if (res?.data.length > 0) {
      setTrackers(res.data);
    }
  }
  const handleRedirect = (event) => {
    window.location.replace("/tracker/" + event.target.value);
  };

  async function signOut() {
    try {
      await Auth.signOut();
      navigate("/");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  const theme = useTheme();

  return (
    <>
      <AppBar position="static" className="main-navigation-bar">
        <Container maxWidth="xl" className="navigation-bar" disableGutters>
          <Toolbar>
            <div style={{ flexGrow: 1 }}>
              <Typography
                variant="h6"
                className="siteTitle"
                sx={{ color: "white" }}
                onClick={() => navigate("/")}
              >
                {CONFIG.site_title}
              </Typography>
            </div>
            {trackers && (
              <FormControl
                sx={{ m: 1, minWidth: 160, mr: 2 }}
                size="small"
                //condensed
                variant="outlined"
                className="select-tracker-form"
              >
                <TextField
                  id="outlined-select-tracker"
                  select
                  label="Tracker"
                  //condensed
                  size="small"
                  value={props.selectedTracker}
                  onChange={handleRedirect}
                  variant="outlined"
                  sx={{
                    ".MuiSelect-icon": {
                      color: "white",
                    },
                    ".MuiSelect-outlined": {
                      color: "white",
                    },
                    // make it white outline
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "white",
                      },
                    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "white",
                      },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "white",
                      },
                    //set label color white
                    "& .MuiInputLabel-outlined": {
                      color: "white",
                    },
                    "&:hover .MuiInputLabel-outlined": {
                      color: "white",
                    },
                    "& .MuiInputLabel-outlined.Mui-focused": {
                      color: "white",
                    },
                  }}
                >
                  {trackers.map((tracker) => (
                    <MenuItem key={tracker.id} value={tracker.id}>
                      {tracker.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            )}

            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              {
                // dark mode toggle icon
              }
              <Tooltip
                title={
                  props.themeMode === "dark"
                    ? "Toggle Light Mode"
                    : "Toggle Dark Mode"
                }
                arrow
              >
                <IconButton
                  aria-haspopup="true"
                  size="large"
                  onClick={() => props.handleThemeChange()}
                  sx={{ mr: 1 }}
                >
                  {props.themeMode === "dark" ? (
                    <LightMode sx={{ color: "white" }} />
                  ) : (
                    <DarkMode sx={{ color: "white" }} />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
            <Tooltip title="Account" arrow>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={(event) => {
                  // set anchorel and open menu
                  setAnchorEl(event.currentTarget);
                  setMenuOpen(true);
                }}
              >
                <AccountCircle sx={{ color: "white" }} />
              </IconButton>
            </Tooltip>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              open={menuOpen}
              onClose={() => setMenuOpen(false)}
            >
              <MenuItem onClick={() => navigate("/settings")}>
                <ListItemIcon>
                  <People fontSize="small" />
                </ListItemIcon>
                <ListItemText>Account</ListItemText>
              </MenuItem>
              <MenuItem onClick={() => navigate("/alert")}>
                <ListItemIcon>
                  <WarningAmber fontSize="small" />
                </ListItemIcon>
                <ListItemText>Alerts</ListItemText>
              </MenuItem>
              <MenuItem onClick={() => signOut()}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                <ListItemText>Sign Out</ListItemText>
              </MenuItem>
            </Menu>
          </Toolbar>
        </Container>
      </AppBar>

      <Container
        maxWidth="false"
        disableGutters
        sx={{ background: theme.palette.gradient }}
      >
        <Container maxWidth="xl" className="main-container">
          {props.children}
        </Container>
      </Container>
    </>
  );
}
