export function minMaxLngLat(lngLat) {
  const minLng = -180;
  const maxLng = 180;
  const minLat = -85;
  const maxLat = 85;

  const lng = Math.max(minLng, Math.min(maxLng, lngLat[0]));
  const lat = Math.max(minLat, Math.min(maxLat, lngLat[1]));

  return [lng, lat];
}
