import React, { useState } from "react";
import moment from "moment";
import DataTableBase from "../DataTableBase";
import Typography from "@mui/material/Typography";
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CsvDownload from "react-json-to-csv";
import SkeletonTable from "../SkeletonTable";
import LocationOptionsDialog from "../LocationOptionsDialog";
import { getAuthHeader } from "../../auth";
import { CONFIG } from "../../config";
import axios from "axios";
import formatDate from "../../Functions/formatDate";

export default function AssetHistoryCard(props) {
  const columns = [
    {
      name: "id",
      options: {
        label: "ID",
        display: false,
      },
    },
    {
      name: "time",
      options: {
        label: "Date",
        customBodyRender: (value, meta) => {
          return formatDate(value);
        },
      },
    },
    {
      name: "geocode",
      options: {
        label: "Last Location",
        customBodyRender: (value, meta) => {
          return (
            <Typography
              onClick={() => {
                props.setCurrent(meta.rowIndex);
                window.scrollTo(0, 0);
              }}
            >
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "battery",
      options: {
        label: "Battery",
        customBodyRender: (value, meta) => {
          return <Typography className="text-center">{value}%</Typography>;
        },
      },
    },
  ];

  async function getLocation(locationID) {
    const headers = await getAuthHeader();
    const apiUrl =
      CONFIG.API_URL +
      "/assets/" +
      props.trackers[0].id +
      "/locations/" +
      locationID;
    const res = await axios.get(apiUrl, { headers: headers });
    return res.data;
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [showLocationOptions, setShowLocationOptions] = useState(false);
  const [location, setLocation] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  async function handleLocationOptions(location) {
    if (location === null) {
      setShowLocationOptions(!showLocationOptions);
      return;
    }
    const locationItem = await getLocation(location);
    setLocation(locationItem);
    setShowLocationOptions(!showLocationOptions);
  }

  return (
    <>
      <Card className="tracker-history-card">
        <CardHeader
          title="History"
          action={
            <div>
              <IconButton
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem>
                  <CsvDownload
                    className="csv-download-button"
                    data={props?.trackerHistory?.map((item) => {
                      return {
                        id: item.id,
                        time: item.time,
                        geocode: item.geocode,
                        battery: item.battery,
                        received_by: item.received_by.length,
                        accuracy: item.accuracy,
                        latitude: item.latitude,
                        longitude: item.longitude,
                      };
                    })}
                    filename={
                      props?.trackerName +
                      "-" +
                      moment.utc(props.date).local().format("L") +
                      ".csv"
                    }
                  >
                    Download CSV
                  </CsvDownload>
                </MenuItem>
              </Menu>
            </div>
          }
        />
        <CardContent>
          {props?.trackerHistory ? (
            <DataTableBase
              data={props?.trackerHistory}
              columns={columns}
              sortOrder="time"
            />
          ) : (
            <SkeletonTable rows={10} columns={3} />
          )}
        </CardContent>
      </Card>
      <LocationOptionsDialog
        location={location}
        handleLocationOptions={handleLocationOptions}
        showLocationOptions={showLocationOptions}
        trackers={props.trackers}
      />
    </>
  );
}
