import mapboxgl from "mapbox-gl";
import updateHotspotsSource from "./updateHotspotSource";
import createGeoJSONCircle from "./createGeoJSONCircle";
import updateCurrentLocation from "./updateCurrentLocation";
import formatDate from "./formatDate";
import { minMaxLngLat } from "./minMaxLngLat";

//add markers for history
export default async function addHistoryMarkers(history, mapRef, setCurrent) {
  if (mapRef?.current) return;

  let features = {
    type: "FeatureCollection",
    features: history.map((item, index) => {
      return createGeoJSONCircle(
        minMaxLngLat([item.longitude, item.latitude]),
        5,
        64,
        item.geocode,
        item.time,
        item.received_by,
        index
      );
    }),
  };

  if (!mapRef.getSource("tag-history-markers")) {
    //add a source for tag history markers
    mapRef.addSource("tag-history-markers", {
      type: "geojson",
      data: features,
    });

    //add a layer for tag history circles use fill to paint the circle
    mapRef.addLayer({
      id: "tag-history-markers-layer",
      type: "fill",
      source: "tag-history-markers",
      paint: {
        "fill-color": "#4ecca3",
        "fill-opacity": 0.5,
        //border 1px green
        "fill-outline-color": "#004d40",
      },
    });

    //when a tag history marker is clicked, fly to that location and show a popup
    mapRef.on("click", "tag-history-markers-layer", function (e) {
      mapRef.flyTo({
        center: [
          history[e.features[0].properties.index].longitude,
          history[e.features[0].properties.index].latitude,
        ],
        maxDuration: 2000,
        essential: true,
        animated: false,
      });

      updateHotspotsSource(
        e.features[0].properties.hotspots,
        mapRef,
        history,
        e.features[0].properties.index
      );

      updateCurrentLocation(
        mapRef,
        history[e.features[0].properties.index],
        e.features[0].properties.index
      );

      setCurrent(e.features[0].properties.index);

      new mapboxgl.Popup()
        .setLngLat([
          history[e.features[0].properties.index].longitude,
          history[e.features[0].properties.index].latitude,
        ])
        .setHTML(
          "<h3 class='text-dark'>" +
            formatDate(e.features[0].properties.time) +
            "</h3><p class='text-dark'><strong>Location:</strong><br /> " +
            e.features[0].properties.title +
            "</p>"
        )
        .addTo(mapRef);
    });
  } else {
    mapRef.getSource("tag-history-markers").setData(features);
  }
}
