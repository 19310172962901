import axios from "axios";
import { getAuthHeader } from "../auth";
import { CONFIG } from "../config";

export default async function getTracker(id) {
  const headers = await getAuthHeader();
  try {
    const apiUrl = CONFIG.API_URL + "/devices/" + id;
    const res = await axios.get(apiUrl, { headers: headers });

    return res.data;
  } catch (e) {
    console.log(e);
  }
}
