import {
  Dialog,
  DialogTitle,
  FormControl,
  TextField,
  Button,
  DialogActions,
  DialogContent,
  Stack,
  Slide,
  IconButton,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { getAuthHeader } from "../../auth";
import { CONFIG } from "../../config";
import axios from "axios";
import AttachFileIcon from "@mui/icons-material/AttachFile";

AddAssetDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddAssetDialog(props) {
  const { onClose, open } = props;
  const [assetName, setAssetName] = useState("New Asset");
  const [assetDescription, setAssetDescription] = useState("New Asset");

  const [preview, setPreview] = useState();
  const [selectedFile, setSelectedFile] = useState();

  function onFileChange(event) {
    const objectUrl = URL.createObjectURL(event.target.files[0]);
    setPreview(objectUrl);

    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = (e) => {
      setSelectedFile(e.target.result);
    };
  }

  const handleClose = () => {
    onClose();
  };

  const addAsset = async () => {
    var apiUrl = CONFIG.API_URL + "/assets";
    const data = {
      name: assetName,
      description: assetDescription,
    };

    const headers = await getAuthHeader();
    const res = await axios.post(apiUrl, data, { headers: headers });

    if (selectedFile) {
      apiUrl = CONFIG.API_URL + "/devices/" + res.data.id + "/image";

      const dataImage = {
        base64_image: selectedFile,
      };

      await axios.post(apiUrl, dataImage, { headers: headers });
    }

    alert("Asset" + assetName + " Added!");
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      className="AddAssetDialog"
      maxWidth="md"
      fullWidth
      TransitionComponent={Transition}
    >
      <DialogTitle>Add an Asset</DialogTitle>
      <DialogContent>
        <Stack spacing={3} sx={{ marginTop: "10px" }}>
          <div>
            {preview && (
              <img
                src={preview}
                alt={assetName}
                width="120"
                height="120"
                class="asset-icon"
              />
            )}
            {!preview && (
              <img
                src="/asset-placeholder.jpeg"
                alt={assetName}
                width="120"
                height="120"
                class="asset-icon"
              />
            )}
            {
              <FormControl>
                <IconButton color="primary" component="label">
                  <input
                    type="file"
                    accept="image/*"
                    hidden
                    onChange={onFileChange}
                  />
                  <AttachFileIcon fontSize="medium" />
                </IconButton>
              </FormControl>
            }
          </div>
          <FormControl>
            <TextField
              label="Asset Name"
              id="assetName"
              value={assetName}
              onChange={(e) => setAssetName(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <TextField
              multiline
              maxRows={4}
              minRows={4}
              label="Asset Description"
              id="assetDescription"
              value={assetDescription}
              onChange={(e) => setAssetDescription(e.target.value)}
            />
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="error"
          onClick={handleClose}
          style={{ marginLeft: "auto" }}
        >
          Cancel
        </Button>
        <Button variant="outlined" onClick={addAsset}>
          Add Asset
        </Button>
      </DialogActions>
    </Dialog>
  );
}
