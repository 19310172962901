import mapboxgl from "mapbox-gl";
import animalHash from "../components/AngryPurpleTiger";

//add Hotspots to map
export default async function addHotspots(
  hotspots,
  mapRef,
  history,
  current = 0
) {
  if (!mapRef.getSource("hotspots-source")) {
    try {
      let features = [];
      let featuresToCurrentLocation = [];
      let currentLocation = {};

      if (history && history.length > 0) {
        // create currentLocation from last history item
        currentLocation = {
          latitude: history[current].latitude,
          longitude: history[current].longitude,
        };
      }

      if (hotspots.length > 0) {
        hotspots.map((item) => {
          if (item.long && item.lat) {
            if (item.long !== 0 && item.lat !== 0) {
              features.push({
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: [item.long, item.lat],
                },
                properties: {
                  id: item.id,
                  rssi: item.rssi,
                  name: animalHash(item.id),
                  snr: item.snr,
                },
              });
            }

            if (history && history.length > 0) {
              //add a current position to hotspot location line
              featuresToCurrentLocation.push({
                type: "Feature",
                geometry: {
                  //lineString
                  type: "LineString",
                  coordinates: [
                    [item.long, item.lat],
                    [currentLocation.longitude, currentLocation.latitude],
                  ],
                },
              });
            }
          }

          return item;
        });
      }

      mapRef.addSource("hotspots-source", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: features,
        },
      });

      //add source for line from hotspot to current location
      mapRef.addSource("hotspots-to-current-location", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: featuresToCurrentLocation,
        },
      });

      //add layer for line from hotspot to current location
      mapRef.addLayer({
        id: "hotspots-to-current-location-layer",
        type: "line",
        source: "hotspots-to-current-location",
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          //light grey line
          "line-color": "#d3d3d3",
          "line-width": 1,
        },
      });

      // add layer for hotspots
      mapRef.addLayer({
        id: "hotspots-layer",
        type: "symbol",
        source: "hotspots-source",
        layout: {
          "icon-image": "hotspot-icon",
          "icon-size": 0.15,
        },
      });

      mapRef.on("click", "hotspots-layer", function (e) {
        new mapboxgl.Popup()
          .setLngLat(e.features[0].geometry.coordinates)
          .setHTML(
            "<h2 class='text-dark'><a  style='color: #00D09F'" +
              "href='https://explorer.helium.com/hotspots/" +
              e.features[0].properties.id +
              "'>" +
              e.features[0].properties.name +
              "</a></h2><p class='text-dark'><strong>RSSI: </strong>" +
              e.features[0].properties.rssi +
              " RSSI <br /><strong>SNR: </strong>" +
              Math.round(
                (e.features[0].properties.snr + Number.EPSILON) * 100
              ) /
                100 +
              " dB</p>"
          )
          .addTo(mapRef);
      });
    } catch (e) {
      console.log(e);
    }
  }
}
