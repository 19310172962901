import React from "react";
import { getAuthHeader } from "../../auth";
import Layout from "../../components/Layout";
import { CONFIG } from "../../config";
import axios from "axios";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Typography,
} from "@mui/material";

export default function TrackerBilling(props) {
  document.title = CONFIG.site_title + " Account Billing Management";
  let params = useParams();
  const [showCardCapture, setShowCardCapture] = React.useState(false);
  const [showCharge, setShowCharge] = React.useState(false);
  const [tracker, setTracker] = React.useState();

  var billingTitle =
    "Billing for your tracker " + tracker?.name + " has expired.";

  var billingSubTitle =
    "Please add a subscription below to continue using this tracker.";

  if (params.activateEarly) {
    billingTitle =
      "Activate a subscription for tracker " + tracker?.name + " early.";
    billingSubTitle = "Please add a subscription below activate this tracker.";
  }

  async function getTracker() {
    const headers = await getAuthHeader();
    const apiUrl = CONFIG.API_URL + "/devices/" + params.id;
    const res = await axios.get(apiUrl, { headers: headers });

    const trackerItems = [
      {
        id: res?.data.id,
        name: res?.data.name,
        billing_status: res?.data.billing_status,
      },
    ];
    setTracker(trackerItems[0]);

    document.title =
      trackerItems[0].name + " Billing Management - " + CONFIG.site_title;
  }

  useEffect(() => {
    getTracker();
    checkUserBillingStats();
    // eslint-disable-next-line
  }, []);

  async function checkUserBillingStats() {
    const authHeader = await getAuthHeader();
    const response = await axios.get(CONFIG.API_URL + "/user", {
      headers: authHeader,
    });

    if (response.data.billing_enabled === false) {
      setShowCardCapture(!showCardCapture);
    } else {
      setShowCharge(!showCharge);
    }
  }

  async function launchPaymentMethodCapturePortal() {
    const headers = await getAuthHeader();
    const response = await axios.get(
      CONFIG.API_URL + "/billing/card-capture-web",
      {
        headers: headers,
      }
    );

    window.location.replace(response.data.url);
  }

  async function AddSubscription() {
    const headers = await getAuthHeader();
    const apiUrl = CONFIG.API_URL + "/devices/" + params.id + "/subscribe";
    await axios.get(apiUrl, { headers: headers }).catch(function (error) {
      if (error.response === 400) {
        alert("Subscription already exists for this device. Redirecting...");
        window.location.href = "/trackers/" + params.id;
      }
    });

    alert("Subscription added successfully. Redirecting...");
    window.location.href = "/trackers/" + params.id;
  }

  return (
    <Box sx={{ my: 3 }}>
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        <Grid item xs={6}>
          <Card>
            <CardHeader title={billingTitle} subheader={billingSubTitle} />

            {showCardCapture === true && (
              <>
                <CardContent>
                  <Typography variant="body1">
                    Please add a payment method to continue setting up a
                    subscription.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    variant="outlined"
                    onClick={launchPaymentMethodCapturePortal}
                  >
                    Add Payment Method
                  </Button>
                </CardActions>
              </>
            )}
            {showCharge === true && (
              <>
                <CardContent>
                  <Typography variant="body1">
                    The charge for 1 year of service for one tracker is $40.{" "}
                  </Typography>
                  <Typography variant="body1">
                    To subscribe please click the Add subscription button to
                    contiune.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button variant="outlined" onClick={AddSubscription}>
                    Add Subscription
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={launchPaymentMethodCapturePortal}
                  >
                    Change Default Payment Method
                  </Button>
                </CardActions>
              </>
            )}
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
