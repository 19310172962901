import axios from "axios";
import isInTheFuture from "./isInTheFuture";
import { CONFIG } from "../config";
import { getAuthHeader } from "../auth";

export default async function checkTrial(device_id) {
  const headers = await getAuthHeader();
  const apiUrl = CONFIG.API_URL + "/devices/" + device_id;
  const res = await axios.get(apiUrl, { headers: headers });
  if (isInTheFuture(res.data.next_billing_date)) {
    //trial has expired, redirect to billing page
    return true;
  } else {
    return false;
  }
}
