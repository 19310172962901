import {
  Dialog,
  DialogTitle,
  FormControl,
  TextField,
  Button,
  DialogActions,
  DialogContent,
  Select,
  MenuItem,
  Stack,
  Slide,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { getAuthHeader } from "../../auth";
import { CONFIG } from "../../config";
import axios from "axios";

AddGroupsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddGroupsDialog(props) {
  const { onClose, open } = props;
  const [groupName, setGroupName] = useState("New Group");
  const [groupDescription, setGroupDescription] = useState("New Group");
  const [groupType, setGroupType] = useState("Work");

  const handleClose = () => {
    onClose();
  };

  const addGroup = async () => {
    const apiUrl = CONFIG.API_URL + "/groups/";
    const data = {
      name: groupName,
      description: groupDescription,
      type: groupType,
    };

    const headers = await getAuthHeader();
    await axios.put(apiUrl, data, { headers: headers });

    alert("Group" + groupName + " Added!");
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      className="AddGroupDialog"
      maxWidth="lg"
      fullWidth
      TransitionComponent={Transition}
    >
      <DialogTitle>Add Group</DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          <FormControl>
            <TextField
              id="groupName"
              label="Group Name"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
              margin="normal"
              variant="outlined"
              fullWidth
            />
          </FormControl>
          <FormControl>
            <TextField
              multiline
              maxRows={4}
              minRows={4}
              id="groupDescription"
              label="Group Description"
              value={groupDescription}
              onChange={(e) => setGroupDescription(e.target.value)}
              margin="normal"
              variant="outlined"
              fullWidth
            />
          </FormControl>
          <FormControl>
            <Select
              id="groupType"
              label="Group Type"
              value={groupType}
              onChange={(e) => setGroupType(e.target.value)}
              margin="normal"
              variant="outlined"
              fullWidth
            >
              <MenuItem value="Work">Work</MenuItem>
              <MenuItem value="Home">Home</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="error"
          onClick={handleClose}
          style={{ marginLeft: "auto" }}
        >
          Cancel
        </Button>
        <Button variant="outlined" onClick={addGroup}>
          Add Group
        </Button>
      </DialogActions>
    </Dialog>
  );
}
