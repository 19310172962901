import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material";
import React from "react";
import { CONFIG } from "../../config";
import axios from "axios";
import { getAuthHeader } from "../../auth";
import DataTableBase from "../../components/DataTableBase";
import moment from "moment";
import SkeletonTable from "../../components/SkeletonTable";
import Layout2 from "../../components/Layout2";

function formatDate(dateUTC) {
  var date = moment.utc(dateUTC).format();
  return (
    <Typography>
      {moment.utc(date).local().format("h:mma")}
      <br />
      {moment.utc(date).local().format("MMM Do")}
    </Typography>
  );
}

export default function Alerts(props) {
  document.title = CONFIG.site_title + " Tracker Alerts";
  const [alerts, setAlerts] = React.useState([]);

  async function getAlerts() {
    const headers = await getAuthHeader();
    const apiUrl = CONFIG.API_URL + "/alerts";
    const res = await axios.get(apiUrl, { headers: headers });

    if (res.data && res.data.length) {
      setAlerts(res.data);
    }
  }

  const columns = [
    {
      name: "id",
      options: {
        display: false,
      },
    },
    {
      name: "alerted_at",
      options: {
        label: "Date",
        customBodyRender: (value, meta) => {
          return formatDate(value);
        },
      },
    },
    {
      name: "device",
      options: {
        label: "Tracker",
        customBodyRender: (value, meta) => {
          return (
            <a href={"/tracker/" + value?.id}>
              <Typography>{value?.name}</Typography>
            </a>
          );
        },
      },
    },
    {
      name: "message",
      options: {
        label: "Alert Message",
        customBodyRender: (value, meta) => {
          return <Typography>{value}</Typography>;
        },
      },
    },
  ];

  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      getAlerts();
      return () => {
        mounted = false;
      };
    }
  }, []);

  return (
    <Box sx={{ py: 3 }}>
      <Card>
        <CardHeader
          title="Alerts"
          subheader="A History of alerts for all trackers on your account."
        />
        <CardContent>
          {alerts ? (
            <DataTableBase
              data={alerts}
              columns={columns}
              sortOrder="alerted_at"
              sortDirection="desc"
            />
          ) : (
            <SkeletonTable rows={10} columns={columns.length} />
          )}
        </CardContent>
      </Card>
    </Box>
  );
}
