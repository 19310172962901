import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { CONFIG } from "../../config";
import { getAuthHeader } from "../../auth";
import axios from "axios";
import { red } from "@mui/material/colors";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import moment from "moment";

export default function LocationOptionsDialog(props) {
  function formatDate(dateUTC) {
    var date = moment.utc(dateUTC).format();
    return moment.utc(date).local().format("h:mma MMM Do YYYY");
  }

  function redirectToLocation(location) {
    window.location.replace(
      "http://maps.google.com/?q=" + encodeURIComponent(location)
    );
  }

  // mark location item error
  async function markAsError(location) {
    const headers = await getAuthHeader();
    const apiUrl =
      CONFIG.API_URL +
      "/devices/" +
      props?.trackers[0]?.id +
      "/locations/" +
      location +
      "/invalidate";
    const res = await axios.put(apiUrl, {}, { headers: headers });

    if (res.status === 202) {
      alert("Location marked as error");
    }
    props.handleLocationOptions(null);
  }

  return (
    <Dialog
      open={props.showLocationOptions}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">
        {formatDate(props?.location?.time)}
      </DialogTitle>
      <DialogContent>
        <Typography>{props?.location?.geocode}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => {
            props.handleLocationOptions(null);
          }}
          startIcon={<ArrowBackIcon />}
        >
          Cancel
        </Button>
        <Button
          sx={{ color: red[600] }}
          variant="outlined"
          startIcon={<DeleteIcon />}
          onClick={() => {
            markAsError(props?.location?.id);
          }}
        >
          Mark as Error
        </Button>
        <Button
          variant="outlined"
          startIcon={<OpenInNewIcon />}
          onClick={() => {
            redirectToLocation(props?.location?.geocode);
          }}
        >
          View on Google Maps
        </Button>
      </DialogActions>
    </Dialog>
  );
}
