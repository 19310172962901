import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { getAuthHeader } from "../../auth";
import { CONFIG } from "../../config";
import axios from "axios";
import TrackerInfoCard from "../../components/TrackerInfoCard";
import getTracker from "../../Functions/getTracker";
import createMap from "../../Functions/createMap";
import getTrackerHistory from "../../Functions/getTrackerHistory";
import moment from "moment";
import mapboxgl from "mapbox-gl";
import { Box, Grid } from "@mui/material";
import TrackerHistoryCard from "../../components/TrackerHistoryCard";
import DateTimePickerDialog from "../../components/DateTImePickerDialog";
import addPolyline from "../../Functions/addPolyline";
import addHistoryMarkers from "../../Functions/addHistoryMarkers";
import updateCurrentLocation from "../../Functions/updateCurrentLocation";
import updateHotspotsSource from "../../Functions/updateHotspotSource";
import getUser from "../../Functions/getUser";
import HistoryPlaybackControl from "../../components/HistoryPlaybackControl";
import TrackerGeofences from "../../components/TrackerGeofences";
import TrackerAlertsCard from "../../components/TrackerAlertsCard";
import getTrackerAlerts from "../../Functions/getTrackerAlerts";
import addGeofences from "../../Functions/addGeofences";
import checkTrialSub from "../../Functions/checkTrialSub";
import { minMaxLngLat } from "../../Functions/minMaxLngLat";

export default function Tracker(props) {
  let params = useParams();
  const mapRef = useRef(null);
  const [tracker, setTracker] = React.useState({});
  const [trackerHistory, setTrackerHistory] = React.useState({});
  const [geofences, setGeofences] = React.useState({});
  const [alerts, setAlerts] = React.useState({});
  const [openDateTimePicker, setOpenDateTimePicker] = useState(false);
  const [followTracker, setFollowTracker] = useState(false);
  const [startDate, setStartDate] = useState(moment().subtract(1, "days"));
  const [endDate, setEndDate] = useState(moment().endOf("day"));
  const [current, setCurrent] = useState(0);
  const [user, setUser] = useState({});
  //store interval in ref
  const intervalRef = useRef();
  const [pitch, setPitch] = useState(0);

  // add controls to mapBox
  function addButtons(map) {
    // button to control pitch
    class PitchToggle {
      constructor({ bearing = 0, pitch = 75, minpitchzoom = null }) {
        this._bearing = bearing;
        this._pitch = pitch;
        this._minpitchzoom = minpitchzoom;
      }

      onAdd(map) {
        this._map = map;
        let _this = this;

        this._btn = document.createElement("button");
        this._btn.className = "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-2d";
        this._btn.type = "button";
        this._btn["aria-label"] = "Toggle Pitch";
        this._btn.onclick = function () {
          if (map.getPitch() === 0) {
            let options = { pitch: _this._pitch, bearing: _this._bearing };
            if (_this._minpitchzoom && map.getZoom() > _this._minpitchzoom) {
              options.zoom = _this._minpitchzoom;
            }
            setPitch(75);
            map.easeTo(options);
            _this._btn.className =
              "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-2d";
          } else {
            setPitch(0);
            map.easeTo({ pitch: 0, bearing: 0 });
            _this._btn.className =
              "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-3d";
          }
        };

        this._container = document.createElement("div");
        this._container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
        this._container.appendChild(this._btn);

        return this._container;
      }

      onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
      }
    }

    class DateTimePickerToggle {
      onAdd(map) {
        this._map = map;

        this._btn = document.createElement("button");
        this._btn.className = "mapboxgl-ctrl-icon mapboxgl-ctrl-datePicker";
        this._btn.type = "button";
        this._btn["aria-label"] = "Set Date/Time Range";
        this._btn.onclick = function () {
          setOpenDateTimePicker(!openDateTimePicker);
        };

        this._container = document.createElement("div");
        this._container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
        this._container.appendChild(this._btn);

        return this._container;
      }

      onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
      }
    }

    class FollowTrackerToggle {
      constructor({ followTrackerToggleVar = false }) {
        this._followTrackerToggleVar = followTrackerToggleVar;
      }
      onAdd(map) {
        this._map = map;
        let _this = this;

        this._btn = document.createElement("button");
        this._btn.className =
          "mapboxgl-ctrl-icon mapboxgl-ctrl-followtoggle-on";
        this._btn.type = "button";
        this._btn["aria-label"] = "Set Date/Time Range";
        this._btn.onclick = function () {
          if (_this._followTrackerToggleVar) {
            _this._followTrackerToggleVar = false;
            setFollowTracker(false);
            _this._btn.className =
              "mapboxgl-ctrl-icon mapboxgl-ctrl-followtoggle-on";
          } else {
            _this._followTrackerToggleVar = true;
            setFollowTracker(true);
            setCurrent(trackerHistory.length - 1);

            _this._btn.className =
              "mapboxgl-ctrl-icon mapboxgl-ctrl-followtoggle-off";
          }
        };

        this._container = document.createElement("div");
        this._container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
        this._container.appendChild(this._btn);

        return this._container;
      }

      onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
      }
    }

    const pitchToggle = new PitchToggle({
      bearing: 0,
      pitch: 0,
      minpitchzoom: null,
    });
    const dateTimePickerToggle = new DateTimePickerToggle();
    const followTrackerToggle = new FollowTrackerToggle({
      followTrackerToggleVar: followTracker,
    });

    map.addControl(new mapboxgl.NavigationControl(), "top-left");
    map.addControl(new mapboxgl.FullscreenControl(), "top-left");
    map.addControl(pitchToggle, "top-left");
    map.addControl(dateTimePickerToggle, "top-left");
    map.addControl(followTrackerToggle, "top-left");
  }

  //update tracker matching setting in local storage
  async function updateTrackerMatchingSetting(setting) {
    localStorage.setItem(params.id + "-trackerMatchingSetting", setting);
    await updateHistory();
  }

  //fetch tracker
  async function fetchTracker() {
    let trackerInfo = await getTracker(params.id);
    setTracker(trackerInfo);
  }

  //fetch history and create map
  async function fetchHistoryAndCreateMap(user) {
    let history = await getTrackerHistory(params.id, startDate, endDate);
    history.reverse();

    //clearn history of null values for lat and lng
    history = history.filter((item) => item.latitude && item.longitude);

    let geofencesFetch = await getGeofences();
    setGeofences(geofencesFetch);

    let alertsFetch = await getTrackerAlerts(params.id, startDate, endDate);

    setAlerts(alertsFetch);

    setTrackerHistory(history);

    let initialLngLat = [0, 0];

    if (history && history.length > 0) {
      initialLngLat = minMaxLngLat([history[0].longitude, history[0].latitude]);
    }

    createMap(
      mapRef,
      history,
      pitch,
      initialLngLat,
      history.length > 0 ? history.length - 1 : 0,
      setCurrent,
      user.helium_enthusiast_mode,
      geofencesFetch,
      localStorage.getItem(params.id + "-trackerMatchingSetting")
    );
    addButtons(mapRef.current);
  }

  async function updateHistory() {
    if (!mapRef.current) return;
    try {
      let history = await getTrackerHistory(params.id, startDate, endDate);
      history.reverse();

      setTrackerHistory(history);
      addPolyline(
        history,
        mapRef.current,
        localStorage.getItem(params.id + "-trackerMatchingSetting")
      );
      addHistoryMarkers(history, mapRef.current, setCurrent);
      updateCurrentLocation(mapRef.current, history, current);
    } catch (error) {
      console.log("updateHistory error", error);
    }
  }

  async function updateGeofences() {
    let geofencesFetch = await getGeofences();

    setGeofences(geofencesFetch);
    addGeofences(mapRef.current, geofencesFetch);
  }

  async function getGeofences() {
    //get geofences
    const headers = await getAuthHeader();
    const apiGeofence = CONFIG.API_URL + "/devices/" + params.id + "/geofences";
    const resGeoFenceRules = await axios.get(apiGeofence, {
      headers: headers,
    });
    return resGeoFenceRules.data;
  }

  //check trial, fetch tracker, fetch history, create map, set interval
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getUser().then((user) => {
        setUser(user);

        checkTrialSub(params.id);
        fetchTracker();
        fetchHistoryAndCreateMap(user);
      });

      intervalRef.current = setInterval(() => {
        updateHistory();
      }, 30000);

      return () => {
        mounted = false;
        clearInterval(intervalRef.current);
        if (mapRef.current) {
          mapRef.current.remove();
          mapRef.current = null;
        }
      };
    }
    // eslint-disable-next-line
  }, []);

  //when followTracker changes, fly to last point in trackerHistory
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (mapRef.current) {
        if (followTracker === true) {
          setCurrent(trackerHistory.length - 1);
        }
      }
    }

    //clear interval and restart
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      updateHistory();
    }, 30000);
    // eslint-disable-next-line
  }, [followTracker]);

  //useEffect for when start and end date change to stop and restart interval
  useEffect(() => {
    if (mapRef.current) {
      updateHistory();
      getGeofences().then((geofences) => {
        setGeofences(geofences);
        addGeofences(mapRef.current, geofences);
      });

      getTrackerAlerts(params.id, startDate, endDate).then((alerts) => {
        setAlerts(alerts);
      });

      getTrackerHistory(params.id, startDate, endDate).then((history) => {
        history.reverse();
        setCurrent(history.length - 1);
      });

      clearInterval(intervalRef.current);
      intervalRef.current = setInterval(() => {
        updateHistory();
        getGeofences().then((geofences) => {
          setGeofences(geofences);
          addGeofences(mapRef.current, geofences);
        });

        getTrackerAlerts(params.id, startDate, endDate).then((alerts) => {
          setAlerts(alerts);
        });
      }, 10000);
    }
    // eslint-disable-next-line
  }, [startDate, endDate]);

  //when current changes, update currentLocation source
  useEffect(() => {
    if (mapRef.current && current && trackerHistory[current]) {
      if (user && user.helium_enthusiast_mode === true) {
        updateHotspotsSource(
          trackerHistory[current]?.received_by || [],
          mapRef.current,
          trackerHistory,
          current
        );
      }

      // pan to current location
      let lngLat = minMaxLngLat([
        trackerHistory[current].longitude,
        trackerHistory[current].latitude,
      ]);

      mapRef.current.easeTo({ center: lngLat });

      updateCurrentLocation(mapRef.current, trackerHistory[current], current);
    }
    // eslint-disable-next-line
  }, [current]);

  //when trackerHistory changes, update history source
  useEffect(() => {
    //if followmode is on, set current to last point in history
    if (followTracker === true) {
      setCurrent(trackerHistory.length - 1);
      //fly to last point in trackerHistory
      mapRef.current.flyTo({
        center: [
          trackerHistory[trackerHistory.length - 1].longitude,
          trackerHistory[trackerHistory.length - 1].latitude,
        ],
        zoom: 15,
        essential: true,
      });
    }
    // eslint-disable-next-line
  }, [trackerHistory]);

  //when pitch changes, update map
  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.easeTo({ pitch: pitch });
    }
    // eslint-disable-next-line
  }, [pitch]);

  return (
    <>
      <Box sx={{ py: 3 }}>
        <div id="top-box">
          <div id="mapPanel" />
          <TrackerInfoCard
            tracker={tracker}
            getTracker={() =>
              getTracker(tracker.id).then((tracker) => {
                setTracker(tracker);
              })
            }
            trackerMatchingSetting={localStorage.getItem(
              params.id + "-trackerMatchingSetting"
            )}
            updateTrackerMatchingSetting={updateTrackerMatchingSetting}
          />
        </div>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={8}>
          <HistoryPlaybackControl
            mapRef={mapRef}
            trackerHistory={trackerHistory}
            current={current}
            setCurrent={setCurrent}
          />
          <TrackerHistoryCard
            trackerHistory={trackerHistory}
            tracker={tracker}
            trackerName={tracker ? tracker.name : ""}
            date={endDate ? endDate : moment().format("YYYY-MM-DD")}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TrackerAlertsCard
            alerts={alerts ? alerts : []}
            date={endDate ? endDate : moment().format("YYYY-MM-DD")}
            tracker={tracker ? tracker : null}
          />
          <TrackerGeofences
            tracker={tracker ? tracker : null}
            geofences={geofences ? geofences : []}
            setGeofences={setGeofences}
            getTracker={fetchTracker}
            updateGeofences={updateGeofences}
          />
        </Grid>
      </Grid>

      <DateTimePickerDialog
        open={openDateTimePicker || false}
        trackerStartDate={startDate}
        setTrackerStartDate={setStartDate}
        trackerEndDate={endDate}
        setTrackerEndDate={setEndDate}
        onClose={setOpenDateTimePicker}
      />
    </>
  );
}
