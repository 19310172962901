import React, { useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import moment from "moment";
import {
  Box,
  CardHeader,
  LinearProgress,
  Menu,
  Skeleton,
  useTheme,
} from "@mui/material";
import PinDropIcon from "@mui/icons-material/PinDrop";
import { useNavigate } from "react-router-dom";
import TrackerSettingsDialog from "../TrackerSettingsDialog";
import { Grid } from "@mui/material";
import { IconButton } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { MenuItem } from "@mui/material";

export default function TrackerInfoCard(props) {
  const [displaySettings, setDisplaySettings] = React.useState(false);
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 600);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [trackerMatchingSetting, setTrackerMatchingSetting] = React.useState(
    props.trackerMatchingSetting
  );

  //on window resize, check if the width is less than 600px and set the isMobile state accordingly
  window.addEventListener("resize", () => {
    const ismobile = window.innerWidth < 600;
    if (ismobile !== isMobile) setIsMobile(ismobile);
  });

  let navigate = useNavigate();

  const handleClose = (value) => {
    setDisplaySettings(false);
    props.getTracker();
  };

  function formatDate(dateUTC) {
    var date = moment.utc(dateUTC).format();
    return moment.utc(date).local().format("h:mma MMM Do YYYY");
  }

  const theme = useTheme();

  useEffect(() => {
    setTrackerMatchingSetting(props.trackerMatchingSetting);
  }, [props.trackerMatchingSetting]);

  return (
    <div
      className={isMobile ? "tracker-info-card-mobile" : "tracker-info-card"}
    >
      <Card
        onClick={() => {
          navigate("/tracker/" + props.tracker.id);
        }}
      >
        <CardHeader
          title={props.tracker?.name || <Skeleton />}
          titleTypographyProps={{ variant: "h5", className: "cardHeader" }}
          subheader={formatDate(props.tracker?.last_location?.time)}
          avatar={
            <img
              src={props.tracker?.image_url || "/tracker-placeholder.jpeg"}
              alt={props.tracker?.name}
              style={{
                borderRadius: "50%",
                borderColor: theme.palette.primary.main,
                borderWidth: "3px",
                borderStyle: "solid",
              }}
              width="40"
              height="40"
            />
          }
          action={
            <>
              <IconButton
                aria-label="settings"
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                  setMenuOpen(true);
                }}
              >
                <MoreVert />
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={() => setMenuOpen(false)}
              >
                <MenuItem
                  onClick={() => {
                    setDisplaySettings(true);
                    setMenuOpen(false);
                  }}
                >
                  Settings
                </MenuItem>
                {
                  //if props.trackerMatchingSetting is true, show the option to turn it off
                  trackerMatchingSetting === "true" ? (
                    <MenuItem
                      onClick={() => {
                        props.updateTrackerMatchingSetting(false);
                        setMenuOpen(false);
                      }}
                    >
                      Turn off route matching
                    </MenuItem>
                  ) : (
                    <MenuItem
                      onClick={() => {
                        props.updateTrackerMatchingSetting(true);
                        setMenuOpen(false);
                      }}
                    >
                      Turn on route matching
                    </MenuItem>
                  )
                }
              </Menu>
            </>
          }
        />

        <CardContent>
          <Typography>
            {props.tracker && props.tracker?.last_location ? (
              <>
                <PinDropIcon sx={{ fontSize: 20 }} />
                {props.tracker?.last_location?.geocode}
              </>
            ) : (
              <>
                <PinDropIcon sx={{ fontSize: 20 }} />
                No Location
              </>
            )}
          </Typography>

          <Box sx={{ display: "flex", mt: 2 }}>
            <Typography variant="body1" sx={{ flexGrow: 1 }}>
              <strong>Battery:</strong>
            </Typography>

            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-around"
              sx={{ maxWidth: "100px", mr: { sm: 0, md: 2 } }}
            >
              <Grid item xs={12} md={8}>
                <LinearProgress
                  variant="determinate"
                  value={props.tracker?.last_location?.battery}
                  max={100}
                  min={0}
                  sx={{ height: 6, borderRadius: "12px" }}
                  color={
                    props.tracker?.last_location?.battery < 20
                      ? "error"
                      : props.tracker?.last_location?.battery < 50
                      ? "warning"
                      : "success"
                  }
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Typography variant="body2" align="center">
                  {props.tracker?.last_location?.battery}%
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
      <TrackerSettingsDialog
        open={displaySettings}
        onClose={handleClose}
        tracker={props?.tracker}
        getTracker={props?.getTracker}
      />
    </div>
  );
}
