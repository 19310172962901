import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Slide,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import React from "react";
import Layout2 from "../../components/Layout2";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { CONFIG } from "../../config";
import axios from "axios";
import { getAuthHeader } from "../../auth";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Settings() {
  document.title = CONFIG.site_title + " Account Settings";
  const navigate = useNavigate();
  const [user, setUser] = React.useState();
  const [userDB, setUserDB] = React.useState();
  const [openDeleteAccount, setOpenDeleteAccount] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [userMeasurement, setUserMeasurement] = React.useState("metric");
  const [accountPublic, setAccountPublic] = React.useState(false);
  const [shareLink, setShareLink] = React.useState("");

  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      getAccountDetails();
    }
    return () => (mounted = false);
  }, []);

  async function getAccountDetails() {
    await Auth.currentAuthenticatedUser({
      bypassCache: false,
    }).then((user) => setUser(user));

    const authHeader = await getAuthHeader();
    const response = await axios.get(CONFIG.API_URL + "/user", {
      headers: authHeader,
    });
    setUserDB(response.data);
    setChecked(response.data.helium_enthusiast_mode);
    setAccountPublic(response.data.public);
    setUserMeasurement(response.data.units);
    setShareLink(CONFIG.PUBLIC_URL + "/account/" + response.data.id);
  }

  async function setHeliumEnthusiastMode(event) {
    setChecked(event.target.checked);
    const authHeader = await getAuthHeader();
    const apiUrl = CONFIG.API_URL + "/helium_enthusiast_mode";
    const json_data = {
      helium_enthusiast_mode: event.target.checked,
    };
    await axios.post(apiUrl, json_data, {
      headers: authHeader,
    });
    getAccountDetails();
  }

  async function openBillingPortal() {
    const headers = await getAuthHeader();
    const response = await axios.get(
      CONFIG.API_URL + "/billing/portal-session",
      {
        headers: headers,
      }
    );

    window.location.replace(response.data.url);
  }

  const deleteAccount = async () => {
    /* const url = `${CONFIG.API_URL}/user`;
    const headers = getAuthHeader();
    const response = await axios.delete(url, { headers });
    if (response.status === 200) {
       const result = await Auth.deleteUser();
    }*/
    await Auth.deleteUser();
  };

  const handleTerminateDialog = () => {
    setOpenDeleteAccount(!openDeleteAccount);
  };

  //manage payment details
  async function managePaymentDetails() {
    const headers = await getAuthHeader();
    const response = await axios
      .get(CONFIG.API_URL + "/billing/card-capture-web", {
        headers: headers,
      })
      .catch((error) => {
        console.log(error);
      });
    if (response.status === 200) {
      window.location.replace(response.data.url);
    }
  }

  async function signOut() {
    try {
      await Auth.signOut();
      navigate("/");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  async function updateUserMesaurements(value) {
    setUserMeasurement(value);

    const authHeader = await getAuthHeader();
    const apiUrl = CONFIG.API_URL + "/units";
    const json_data = {
      units: value,
    };
    await axios.post(apiUrl, json_data, {
      headers: authHeader,
    });
    getAccountDetails();
  }

  async function setAccountShared() {
    setAccountPublic(!accountPublic);
    const authHeader = await getAuthHeader();
    const apiUrl = CONFIG.API_URL + "/sharing";
    const json_data = {
      shared: !accountPublic,
    };
    await axios.post(apiUrl, json_data, {
      headers: authHeader,
    });
  }

  return (
    <Box sx={{ py: 3 }}>
      <Card>
        <CardHeader
          title="Account"
          subheader="Modify your account settings & billing"
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography variant="body1" className="strong">
                Name
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography variant="body1">{user?.attributes.name}</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="body1" className="strong">
                Email
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography variant="body1">{user?.attributes.email}</Typography>
            </Grid>
          </Grid>
          <hr />
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography variant="body1" className="strong pt-2">
                Helium Enthusiast Mode
              </Typography>
              <Typography variant="body2" className="pt-1">
                Enable this mode to see hotspot connections on the map
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Checkbox
                checked={checked}
                onChange={setHeliumEnthusiastMode}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="body1" className="strong pt-2">
                Make Account Public
              </Typography>
              <Typography variant="body2" className="pt-1">
                Enable this to share a public view only link with anyone
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Stack direction="row" spacing={2}>
                <Checkbox
                  inputProps={{ "aria-label": "controlled" }}
                  checked={accountPublic}
                  onChange={() => setAccountShared(accountPublic)}
                />
                <Paper
                  component={Stack}
                  direction="column"
                  justifyContent="center"
                >
                  {accountPublic && (
                    <Typography variant="body1">
                      <a href={shareLink}>{shareLink}</a>
                    </Typography>
                  )}
                </Paper>
              </Stack>
            </Grid>

            <Grid item xs={12} md={3}>
              <Typography variant="body1" className="strong pt-2">
                Show Distances in
              </Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl variant="outlined" className="w-100">
                <TextField
                  id="userMeasurement"
                  select
                  label="Show Distance in"
                  value={userMeasurement}
                  onChange={(e) => updateUserMesaurements(e.target.value)}
                >
                  <MenuItem value={"metric"}>Metric (Kilometers)</MenuItem>
                  <MenuItem value={"imperial"}>Imperial (Miles)</MenuItem>
                </TextField>
              </FormControl>
            </Grid>
          </Grid>
          {userDB?.whitelabel?.custom_billing !== true && (
            <>
              <hr />
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <Typography variant="body-1" className="strong">
                    Billing Status
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Typography variant="body-1">
                    {userDB?.billing_enabled ? "Active" : "Inactive"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3} className="pt-3">
                  <Typography variant="body-1" className="strong">
                    Billing Portal
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9} className="pt-3">
                  <Button
                    variant="outlined"
                    onClick={openBillingPortal}
                    startIcon={<OpenInNewIcon />}
                  >
                    Billing Portal
                  </Button>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography variant="body-1" className="strong pt-3">
                    Manage Payment Method
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9} className="pt-3">
                  <Button
                    variant="outlined"
                    onClick={managePaymentDetails}
                    startIcon={<OpenInNewIcon />}
                  >
                    Payment Method
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
          <hr />
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography variant="body-1" className="strong">
                Delete Account
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Button
                variant="outlined"
                color="error"
                onClick={handleTerminateDialog}
                startIcon={<OpenInNewIcon />}
              >
                Close Account
              </Button>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions style={{ justifyContent: "flex-end" }}>
          <Button variant="outlined" onClick={signOut}>
            Logout
          </Button>
        </CardActions>
      </Card>
      <Dialog
        open={openDeleteAccount}
        TransitionComponent={Transition}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Terminate your account?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you wish to terminate your account? This will delete
            all location history and devices from our system.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleTerminateDialog}>Cancel</Button>
          <Button onClick={deleteAccount} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
