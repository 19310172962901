export const CONFIG = {
  API_URL: "https://api.trackpac.io",
  APP_URL: "https://app.trackpac.io",
  PUBLIC_URL: "https://public.trackpac.io",
  whitelabel: "trackpac",
  stripe_live_publishable_key:
    "pk_live_51L31U4FQgDztaRvxcXvgsWgiEqMnUjs0sBTGb2WSZSoMIaD9zErcBys9T5DeMZLqNGQbdUY5PipCuoTCJ52cZC1p00dYsmj4Uh",
  stripe_test_publishable_key:
    "pk_test_51L31U4FQgDztaRvx7kMVoYegWAq9GFgsncJJ6my8bKk9ui3Q8UrhvEvuLkLkRGfzNcZLs4S7FEUgm9GiPZVI5SFH00nsMyaObl",
  site_title: "Lost Paws TT",
  jawg_access_token:
    "OZOoaSWS7fwhHPInvjAJHZVYEMqtqbssjhigvLW7wNbqffUAMRYqmPswCIhqEWQe",
};
