import mapboxgl from "mapbox-gl";

// mapbox matching api call
async function getMatch(coordinates) {
  if (coordinates.length < 3) {
    return coordinates;
  }

  // Create the query
  const query = await fetch(
    `https://api.mapbox.com/directions/v5/mapbox/driving/${coordinates}?access_token=${mapboxgl.accessToken}&geometries=geojson`,
    { method: "GET" }
  );
  const response = await query.json();

  // Handle errors
  if (response.code !== "Ok") {
    console.log("response error", response);
    return coordinates;
  }
  return response.routes[0].geometry.coordinates;
}

// use mapbox matching api to clean up the line
export default async function getCleanedLine(coordinates) {
  try {
    // split coordinates into 25 point chunks, if last chunk is less than 100 points, add it to the previous chunk
    let chunks = [];
    let chunk = [];
    for (let i = 0; i < coordinates.length; i++) {
      if (chunk.length < 25) {
        chunk.push(coordinates[i]);
      } else {
        chunks.push(chunk);
        chunk = [];
        chunk.push(coordinates[i]);
      }
    }
    if (chunk.length > 0) {
      chunks.push(chunk);
    }

    // get the cleaned line for each chunk
    let cleanedLines = [];
    for (let i = 0; i < chunks.length; i++) {
      let cleanedLine = await getMatch(chunks[i].join(";"));
      cleanedLines.push(cleanedLine);
    }

    // reduce the array of arrays to a single array
    cleanedLines = cleanedLines.reduce((acc, val) => acc.concat(val), []);

    return cleanedLines;
  } catch (e) {
    console.log("error cleaning line", e);
    return coordinates;
  }
}
