import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import DataTableBase from "../../components/DataTableBase";
import { CONFIG } from "../../config";
import axios from "axios";
import { getAuthHeader } from "../../auth";
import AddAssetDialog from "../../components/AddAssetDialog";
import SkeletonTable from "../../components/SkeletonTable";
import moment from "moment";
import Layout2 from "../../components/Layout2";

function formatDate(dateUTC) {
  var date = moment.utc(dateUTC).format();
  return moment.utc(date).local().format("lll");
}

export default function Asset() {
  document.title = CONFIG.site_title + " Asset Management";

  const [assets, setAssets] = React.useState([]);
  const [assetID, setAssetID] = React.useState("");
  const [displayAddAsset, setDisplayAddAsset] = React.useState(false);
  const [displayDeleteAssetDialog, setDisplayDeleteAssetDialog] =
    React.useState(false);

  function closeDisplayAsset() {
    setDisplayAddAsset(!displayAddAsset);
    getAssets();
  }

  async function getAssets() {
    const headers = await getAuthHeader();
    const apiUrl = CONFIG.API_URL + "/assets";
    const res = await axios.get(apiUrl, { headers: headers });

    if (res.data && res.data.length) {
      setAssets(res.data);
    }
  }

  async function deleteAsset(assetId) {
    const headers = await getAuthHeader();
    const apiUrl = CONFIG.API_URL + "/assets/" + assetId;
    await axios.delete(apiUrl, { headers: headers });

    getAssets();
  }

  const columns = [
    {
      name: "id",
      options: {
        display: false,
      },
    },
    {
      name: "name",
      options: {
        label: "Name",
        customBodyRender: (value, meta) => {
          return <a href={"/asset/" + meta?.rowData[0]}>{value}</a>;
        },
      },
    },
    {
      name: "description",
      options: {
        label: "Description",
        customBodyRender: (value, meta) => {
          return <Typography>{value}</Typography>;
        },
      },
    },
    {
      name: "last_location",
      options: {
        label: "Last Checkin",
        customBodyRender: (value, meta) => {
          return <Typography>{formatDate(value?.time)}</Typography>;
        },
      },
    },
    {
      name: "last_location",
      options: {
        label: "Last Location",
        customBodyRender: (value, meta) => {
          return <Typography>{value?.geocode || "No Data Yet"}</Typography>;
        },
      },
    },
    {
      name: "last_location",
      options: {
        label: "Battery Level",
        customBodyRender: (value, meta) => {
          return <Typography>{value?.battery || 0}%</Typography>;
        },
      },
    },
    {
      name: "id",
      options: {
        label: "Options",
        customBodyRender: (value, meta) => {
          return (
            <Stack spacing={3}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  window.location.href = "/asset/" + value;
                }}
              >
                View Asset
              </Button>
              <Button
                variant="outlined"
                color="error"
                onClick={() => {
                  setAssetID(meta?.rowData[0]);
                  setDisplayDeleteAssetDialog(true);
                }}
              >
                Delete
              </Button>
            </Stack>
          );
        },
      },
    },
  ];

  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      getAssets();
      return () => {
        mounted = false;
      };
    }
  }, []);

  return (
    <Layout2>
      <Card>
        <CardHeader
          title="Assets"
          action={
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setDisplayAddAsset(true)}
            >
              Create An Asset
            </Button>
          }
        />
        <CardContent>
          {assets ? (
            <DataTableBase
              data={assets}
              columns={columns}
              sortOrder="name"
              sortDirection="asc"
            />
          ) : (
            <SkeletonTable rows={10} columns={3} />
          )}
        </CardContent>
      </Card>
      <AddAssetDialog
        open={displayAddAsset}
        onClose={() => closeDisplayAsset()}
      />
      <Dialog
        open={displayDeleteAssetDialog}
        onClose={() => setDisplayDeleteAssetDialog(false)}
      >
        <DialogTitle>Add an Asset</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this asset?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setDisplayDeleteAssetDialog(false);
            }}
          >
            Cancel
          </Button>

          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              deleteAsset(assetID);
              setDisplayDeleteAssetDialog(false);
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Layout2>
  );
}
