import React from "react";
import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DataTableBase from "../DataTableBase";
import AddGeofence from "../AddGeofenceDialog";

import { CONFIG } from "../../config";
import axios from "axios";
import { getAuthHeader } from "../../auth";
import { Plus } from "react-bootstrap-icons";

export default function TrackerGeofences(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [addGeofenceModal, setAddGeofenceModal] = React.useState(false);

  const toggleGeofenceModal = () => {
    setAddGeofenceModal(!addGeofenceModal);
    handleClose();
  };

  async function deleteGeofence(id, name) {
    const headers = await getAuthHeader();
    const apiGeofence =
      CONFIG.API_URL + "/devices/" + props.tracker.id + "/geofences/" + id;

    await axios.delete(apiGeofence, { headers: headers });

    alert("Geofence " + name + " deleted successfully");
    await props.updateGeofences();
    handleClose();
  }

  async function AddTrackerGeofence(geofenceName, radius, position) {
    if (props.tracker) {
      let geofence = {
        name: geofenceName,
        radius: radius,
        latitude: position.lat,
        longitude: position.lng,
      };

      const headers = await getAuthHeader();
      const apiGeofence =
        CONFIG.API_URL + "/devices/" + props.tracker.id + "/geofences";
      await axios.post(apiGeofence, geofence, { headers: headers });

      alert(`Geofence ${geofenceName} added successfully`);

      await props.updateGeofences();
      toggleGeofenceModal();
      handleClose();
    }
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const confirmDeleteGeofence = (id, name) => {
    if (
      window.confirm(
        "Are you sure you want to delete the geofence " + name + "?"
      )
    ) {
      deleteGeofence(id, name);
      props.getTracker();
    }
  };

  const columns = [
    {
      name: "name",
      options: {
        label: "Name",
        customBodyRender: (value, meta) => {
          return <Typography>{value}</Typography>;
        },
      },
    },
    {
      name: "radius",
      options: {
        label: "Raduis",
        customBodyRender: (value, meta) => {
          return <Typography>{value}m</Typography>;
        },
      },
    },
    {
      name: "id",
      options: {
        label: "Actions",
        customBodyRender: (value, meta) => {
          return (
            <Button
              variant="outlined"
              color="error"
              onClick={() => confirmDeleteGeofence(value, meta.rowData[0])}
            >
              Delete
            </Button>
          );
        },
      },
    },
  ];

  return (
    <Box sx={{ my: 3 }}>
      <DataTableBase
        data={
          props.geofences && props.geofences.length > 0 ? props.geofences : []
        }
        columns={columns}
        sortOrder="name"
        sortDirection="asc"
        title="Geofences"
        customToolbar={
          <Tooltip title="Add Geofence">
            <IconButton
              aria-label="add-geofence"
              onClick={toggleGeofenceModal}
              size="large"
            >
              <Plus />
            </IconButton>
          </Tooltip>
        }
      />
      <AddGeofence
        tracker={props.tracker}
        toggleGeofenceModal={toggleGeofenceModal}
        AddTrackerGeofence={AddTrackerGeofence}
        addGeofenceModal={addGeofenceModal}
        handleClose={handleClose}
        mode={props.mode}
      />
    </Box>
  );
}
