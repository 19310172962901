import React from "react";
import Typography from "@mui/material/Typography";
import DataTableBase from "../DataTableBase";
import formatDate from "../../Functions/formatDate";

export default function TrackerAlertsCard(props) {
  const columns = [
    {
      name: "id",
      options: {
        label: "ID",
        display: false,
        customBodyRender: (value, meta) => {
          return <Typography>{value}</Typography>;
        },
      },
    },
    {
      name: "alerted_at",
      options: {
        label: "Date",
        customBodyRender: (value, meta) => {
          return <Typography>{formatDate(value)}</Typography>;
        },
      },
    },
    {
      name: "message",
      options: {
        label: "Alert",
        customBodyRender: (value, meta) => {
          return <Typography>{value}</Typography>;
        },
      },
    },
  ];

  return (
    <DataTableBase
      data={props.alerts && props.alerts.length > 0 ? props.alerts : []}
      columns={columns}
      sortOrder="alerted_at"
      sortDirection="desc"
      title="Alerts"
    />
  );
}
