import MUIDataTable from "mui-datatables";
import { useState } from "react";

export default function DataTableBase(props) {
  const [rowsPerPage, setRowsPerPage] = useState(props.rowsPerPage || 5);

  const options = {
    responsive: "standard",
    print: false,
    selectableRows: "none",
    sortOrder: {
      name: props.sortOrder || "time",
      direction: props.sortDirection || "desc",
    },
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    rowsPerPage: rowsPerPage,
    filter: props.filter || false,
    search: props.search || false,
    viewColumns: false,
    download: props.download || false,
    onTableChange: (action, newTableState) => {
      switch (action) {
        case "changeRowsPerPage":
          setRowsPerPage(newTableState.rowsPerPage);
          break;
        default:
          break;
      }
    },
    onColumnViewChange: (changedColumn, display) => {
      var newCols = this.columns.slice();
      for (var ii = 0; ii < newCols.length; ii++) {
        if (newCols[ii].name === changedColumn) {
          newCols[ii].options.display = display === "add" ? true : false;
        }
      }
      this.setColumns(newCols);
    },
    //set pagination options
    pagination: true,
    paginationOptions: {
      rowsPerPageOptions: [5, 10, 25, 50, 100],
      rowsPerPage: rowsPerPage,
    },
    //set default rows per page
    rowsPerPageOptions: [5, 10, 25, 50, 100],
    //set the toolbar to be displayed
    //add a button if props.customToolbar is set
    ...(props.customToolbar && {
      customToolbar: () => {
        return props.customToolbar;
      },
    }),
  };

  return <MUIDataTable {...props} options={options} />;
}
