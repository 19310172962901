import axios from "axios";
import { getAuthHeader } from "../auth";
import { CONFIG } from "../config";
import moment from "moment";
import "moment-timezone";

const tz = moment.tz.guess();

export default async function getTrackerHistory(id, start, end) {
  try {
    const headers = await getAuthHeader();

    var UTCCorrectedStartDate = moment.tz(start, tz).utc().toISOString();

    var UTCCorrectedEndDate = moment.tz(end, tz).utc().toISOString();

    const apiURLLocations =
      CONFIG.API_URL +
      "/devices/" +
      id +
      "/locations?start=" +
      UTCCorrectedStartDate +
      "&end=" +
      UTCCorrectedEndDate;

    //get device location history
    const res2 = await axios.get(apiURLLocations, { headers: headers });

    return res2.data;
  } catch (error) {
    console.log(error);
  }
}
