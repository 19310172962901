const animals = [
  "alligator",
  "bee",
  "bird",
  "camel",
  "cat",
  "cheetah",
  "chicken",
  "cow",
  "dog",
  "corgi",
  "eagle",
  "elephant",
  "fish",
  "fox",
  "toad",
  "giraffe",
  "hippo",
  "kangaroo",
  "kitten",
  "lobster",
  "monkey",
  "octopus",
  "pig",
  "puppy",
  "rabbit",
  "rat",
  "scorpion",
  "seal",
  "sheep",
  "snail",
  "spider",
  "tiger",
  "turtle",
  "newt",
  "tadpole",
  "frog",
  "tarantula",
  "albatross",
  "blackbird",
  "canary",
  "crow",
  "cuckoo",
  "dove",
  "pigeon",
  "falcon",
  "finch",
  "flamingo",
  "goose",
  "seagull",
  "hawk",
  "jay",
  "mockingbird",
  "kestrel",
  "kookaburra",
  "mallard",
  "nightingale",
  "nuthatch",
  "ostrich",
  "owl",
  "parakeet",
  "parrot",
  "peacock",
  "pelican",
  "penguin",
  "pheasant",
  "piranha",
  "raven",
  "robin",
  "rooster",
  "sparrow",
  "stork",
  "swallow",
  "swan",
  "swift",
  "turkey",
  "vulture",
  "woodpecker",
  "wren",
  "butterfly",
  "barbel",
  "carp",
  "cod",
  "crab",
  "eel",
  "goldfish",
  "haddock",
  "halibut",
  "jellyfish",
  "perch",
  "pike",
  "mantaray",
  "salmon",
  "sawfish",
  "scallop",
  "shark",
  "shell",
  "shrimp",
  "trout",
  "ant",
  "aphid",
  "beetle",
  "caterpillar",
  "dragonfly",
  "cricket",
  "fly",
  "grasshopper",
  "ladybug",
  "millipede",
  "moth",
  "wasp",
  "anteater",
  "antelope",
  "armadillo",
  "badger",
  "bat",
  "beaver",
  "bull",
  "chimpanzee",
  "dachshund",
  "deer",
  "dolphin",
  "elk",
  "moose",
  "gazelle",
  "gerbil",
  "goat",
  "bear",
  "hamster",
  "hare",
  "hedgehog",
  "horse",
  "hyena",
  "lion",
  "llama",
  "lynx",
  "mammoth",
  "marmot",
  "mink",
  "mole",
  "mongoose",
  "mouse",
  "mule",
  "otter",
  "panda",
  "platypus",
  "pony",
  "porcupine",
  "puma",
  "raccoon",
  "reindeer",
  "rhino",
  "skunk",
  "sloth",
  "squirrel",
  "weasel",
  "snake",
  "wolf",
  "zebra",
  "boa",
  "chameleon",
  "copperhead",
  "cottonmouth",
  "crocodile",
  "rattlesnake",
  "gecko",
  "iguana",
  "lizard",
  "python",
  "salamander",
  "sidewinder",
  "whale",
  "tortoise",
  "lemur",
  "rook",
  "koala",
  "donkey",
  "ferret",
  "tardigrade",
  "orca",
  "okapi",
  "liger",
  "unicorn",
  "dragon",
  "squid",
  "ape",
  "gorilla",
  "baboon",
  "cormorant",
  "mantis",
  "tapir",
  "capybara",
  "pangolin",
  "opossum",
  "wombat",
  "aardvark",
  "starfish",
  "shetland",
  "narwhal",
  "worm",
  "hornet",
  "viper",
  "stallion",
  "jaguar",
  "panther",
  "bobcat",
  "leopard",
  "osprey",
  "cougar",
  "dalmatian",
  "terrier",
  "duck",
  "sealion",
  "raccoon",
  "chipmunk",
  "loris",
  "poodle",
  "orangutan",
  "gibbon",
  "meerkat",
  "huskie",
  "barracuda",
  "bison",
  "caribou",
  "chinchilla",
  "coyote",
  "crane",
  "dinosaur",
  "lark",
  "griffin",
  "yeti",
  "troll",
  "seahorse",
  "walrus",
  "yak",
  "wolverine",
  "boar",
  "alpaca",
  "porpoise",
  "manatee",
  "guppy",
  "condor",
  "cyborg",
  "cobra",
  "locust",
  "mandrill",
  "oyster",
  "urchin",
  "quail",
  "sardine",
  "ram",
  "starling",
  "wallaby",
  "buffalo",
  "goblin",
  "tuna",
  "mustang",
];

export default animals;
