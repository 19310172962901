import mapboxgl from "mapbox-gl";
import Map, {
  Popup,
  ScaleControl,
  FullscreenControl,
  Layer,
  Marker,
  NavigationControl,
  Source,
} from "react-map-gl";
import { useEffect, useRef, useState } from "react";
import DateTimePickerDialog from "../DateTImePickerDialog";

import moment from "moment";
import "moment-timezone";
import LocationOptionsDialog from "../LocationOptionsDialog";
import { Button, useTheme } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import HistoryPlaybackControl from "../HistoryPlaybackControl";
import animalHash from "../AngryPurpleTiger";
import * as turf from "@turf/turf";

//mapbox access token
mapboxgl.accessToken =
  "pk.eyJ1IjoiYmZnbmVpbCIsImEiOiJjbGR2azExeW4wZ28yM29tZm4xZjJwdHFxIn0.Mdv--BkZwKSw-pdTRMQdPg";

export default function MapPanel(props) {
  const [cleanType, setCleanType] = useState("driving");
  const mapRef = useRef(null);
  const [hotspotData, setHotspotData] = useState(null);
  const [markerData, setMarkerData] = useState(null);
  const [trackerData, setTrackerData] = useState(null);
  const [showTrackerPopup, setShowTrackerPopup] = useState(false);
  const [showHotspotPopup, setShowHotspotPopup] = useState(false);
  const [showMarkerPopup, setShowMarkerPopup] = useState(false);
  const [showLocationOptions, setShowLocationOptions] = useState(false);
  const [location, setLocation] = useState(null);
  const [openDateTimePicker, setOpenDateTimePicker] = useState(false);
  const [followTracker, setFollowTracker] = useState(false);
  const [trackerHistory, setTrackerHistory] = useState(null);
  const [trackerHistoryMarkers, setTrackerHistoryMarkers] = useState(null);
  const [hotspotsToCurrentPosition, setHotspotsToCurrentPosition] = useState(
    []
  );
  const [pitch, setPitch] = useState(75);
  const [zoom, setZoom] = useState(17);
  const [bearing, setBearing] = useState(0);

  // get routePin var from url
  const urlParams = new URLSearchParams(window.location.search);
  const [routePin] = useState(urlParams.get("routePin") || false);

  // basic checks for mobile
  const isMobile = window.innerWidth < 600;

  // guess timezone
  const tz = moment.tz.guess();

  // get theme for styling
  const theme = useTheme();

  function minMaxLngLat(lngLat) {
    const minLng = -180;
    const maxLng = 180;
    const minLat = -85;
    const maxLat = 85;

    const lng = Math.max(minLng, Math.min(maxLng, lngLat[0]));
    const lat = Math.max(minLat, Math.min(maxLat, lngLat[1]));

    return [lng, lat];
  }

  function minMaxLng(lng) {
    const minLng = -180;
    const maxLng = 180;
    return Math.max(minLng, Math.min(maxLng, lng));
  }

  function minMaxLat(lat) {
    const minLat = -85;
    const maxLat = 85;
    return Math.max(minLat, Math.min(maxLat, lat));
  }

  // create a geojson circle for a geofence
  var createGeoJSONCircle = function (center, radiusInMeters, points) {
    if (!points) points = 64;

    var coords = {
      latitude: center[1],
      longitude: center[0],
    };

    //convert meters to km
    var km = radiusInMeters / 1000;

    var ret = [];
    var distanceX = km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
    var distanceY = km / 110.574;

    var theta, x, y;
    for (var i = 0; i < points; i++) {
      theta = (i / points) * (2 * Math.PI);
      x = distanceX * Math.cos(theta);
      y = distanceY * Math.sin(theta);

      ret.push([coords.longitude + x, coords.latitude + y]);
    }
    ret.push(ret[0]);

    const feature = {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [ret],
      },
    };

    return feature;
  };

  // create a collection of geojson circles for geofences
  function createGeoJSONCircleCollection(geofences) {
    let geoJSONCircleCollection = [];
    geofences.forEach((geofence) => {
      geoJSONCircleCollection.push(
        createGeoJSONCircle(
          [geofence.longitude, geofence.latitude],
          geofence.radius,
          64
        )
      );
    });
    // build a collection of features
    const featureCollection = {
      type: "FeatureCollection",
      features: geoJSONCircleCollection,
    };
    return featureCollection;
  }

  // handle location options dialog toggle
  function handleLocationOptions(location) {
    setLocation(location);
    setShowLocationOptions(!showLocationOptions);
  }

  // add controls to mapBox
  function addButtons(map) {
    // button to control pitch
    class PitchToggle {
      constructor({ bearing = 0, pitch = 75, minpitchzoom = null }) {
        this._bearing = bearing;
        this._pitch = pitch;
        this._minpitchzoom = minpitchzoom;
      }

      onAdd(map) {
        this._map = map;
        let _this = this;

        this._btn = document.createElement("button");
        this._btn.className = "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-2d";
        this._btn.type = "button";
        this._btn["aria-label"] = "Toggle Pitch";
        this._btn.onclick = function () {
          if (map.getPitch() === 0) {
            let options = { pitch: _this._pitch, bearing: _this._bearing };
            if (_this._minpitchzoom && map.getZoom() > _this._minpitchzoom) {
              options.zoom = _this._minpitchzoom;
            }
            setPitch(75);
            map.easeTo(options);
            _this._btn.className =
              "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-2d";
          } else {
            setPitch(0);
            map.easeTo({ pitch: 0, bearing: 0 });
            _this._btn.className =
              "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-3d";
          }
        };

        this._container = document.createElement("div");
        this._container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
        this._container.appendChild(this._btn);

        return this._container;
      }

      onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
      }
    }

    class DateTimePickerToggle {
      onAdd(map) {
        this._map = map;

        this._btn = document.createElement("button");
        this._btn.className = "mapboxgl-ctrl-icon mapboxgl-ctrl-datePicker";
        this._btn.type = "button";
        this._btn["aria-label"] = "Set Date/Time Range";
        this._btn.onclick = function () {
          setOpenDateTimePicker(!openDateTimePicker);
        };

        this._container = document.createElement("div");
        this._container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
        this._container.appendChild(this._btn);

        return this._container;
      }

      onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
      }
    }

    class FollowTrackerToggle {
      constructor({ followTrackerToggleVar = false }) {
        this._followTrackerToggleVar = followTrackerToggleVar;
      }
      onAdd(map) {
        this._map = map;
        let _this = this;

        this._btn = document.createElement("button");
        this._btn.className =
          "mapboxgl-ctrl-icon mapboxgl-ctrl-followtoggle-on";
        this._btn.type = "button";
        this._btn["aria-label"] = "Set Date/Time Range";
        this._btn.onclick = function () {
          if (_this._followTrackerToggleVar) {
            _this._followTrackerToggleVar = false;
            setFollowTracker(false);
            _this._btn.className =
              "mapboxgl-ctrl-icon mapboxgl-ctrl-followtoggle-on";
          } else {
            _this._followTrackerToggleVar = true;
            setFollowTracker(true);
            _this._btn.className =
              "mapboxgl-ctrl-icon mapboxgl-ctrl-followtoggle-off";
          }
        };

        this._container = document.createElement("div");
        this._container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
        this._container.appendChild(this._btn);

        return this._container;
      }

      onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
      }
    }

    const pitchToggle = new PitchToggle({
      bearing: 0,
      pitch: pitch,
      minpitchzoom: null,
    });
    const dateTimePickerToggle = new DateTimePickerToggle();
    const followTrackerToggle = new FollowTrackerToggle({
      followTrackerToggleVar: followTracker,
    });

    map.addControl(pitchToggle, "top-left");
    map.addControl(dateTimePickerToggle, "top-left");
    map.addControl(followTrackerToggle, "top-left");
  }

  //create layer for tracker history lines
  var trackerHistoryLayer = {
    id: "route",
    type: "line",
    paint: {
      "line-color": theme.palette.primary.main,
      "line-width": 5,
      "line-opacity": 1,
    },
  };

  //create layer for lines from currentPosition to hotspot locations
  var hotspotsToCurrentPositionLayer = {
    id: "hotspots-to-current-position",
    type: "line",
    paint: {
      "line-color": "#888",
      "line-width": 2,
      "line-opacity": 0.75,
    },
  };

  //workout speed
  function getSpeed(lastLocation, currentLocation) {
    if (!lastLocation || !currentLocation) return 0;
    const currentLocationItem = minMaxLngLat([
      currentLocation.longitude,
      currentLocation.latitude,
    ]);
    const lastLocationItem = minMaxLngLat([
      lastLocation.longitude,
      lastLocation.latitude,
    ]);

    const lastLocationTime = new Date(lastLocation.time);
    const currentLocationTime = new Date(currentLocation.time);

    const measurementUnit =
      props.userMeasurementUnit === "metric" ? "kilometers" : "miles";

    const distance = turf.distance(
      turf.point(currentLocationItem),
      turf.point(lastLocationItem),
      { units: measurementUnit }
    );

    //find difference between current and last location time in seconds
    const timeDifference = (currentLocationTime - lastLocationTime) / 1000;

    //find speed in km/h
    const speed = distance / (timeDifference / 3600);

    return speed.toFixed(2);
  }

  //geofence layer style
  const geofenceLayer = {
    // layer styles for polygon
    id: "geofence",
    type: "fill",
    paint: {
      "fill-color": theme.palette.primary.main,
      "fill-opacity": 0.5,
    },
  };

  //mapbox matching api call
  async function getMatch(coordinates, radius, profile) {
    // Separate the radiuses with semicolons
    const radiuses = radius.join(";");
    // Create the query
    const query = await fetch(
      `https://api.mapbox.com/matching/v5/mapbox/${profile}/${coordinates}?geometries=geojson&radiuses=${radiuses}&steps=true&access_token=${mapboxgl.accessToken}`,
      { method: "GET" }
    );
    const response = await query.json();

    // Handle errors
    if (response.code !== "Ok") {
      console.log("response error", response);
    }

    var results = response.matchings.map((matching) => {
      return matching.geometry.coordinates;
    });

    //reduce the array of arrays to a single array
    results = results.reduce((acc, val) => acc.concat(val), []);

    return results;
  }

  // use mapbox matching api to clean up the line
  async function getCleanedLine(coordinates) {
    if (coordinates.length < 101) {
      var response = await getMatch(
        coordinates.join(";"),
        coordinates.map(() => 25),
        cleanType
      );

      return response;
    } else {
      var chunks = [];
      var i,
        j,
        temparray,
        chunk = 100;
      for (i = 0, j = coordinates.length; i < j; i += chunk) {
        temparray = coordinates.slice(i, i + chunk);
        chunks.push(temparray);
      }

      //get match for each chunk and reduce to one array
      var cleanedCoordinates = [];
      chunks.map(async (chunk) => {
        var response = await getMatch(
          chunk.join(";"),
          chunk.map(() => 25),
          cleanType
        );

        response.map((point) => {
          cleanedCoordinates.push(point);
          return null;
        });

        //reduce to one array
        return null;
      });

      return cleanedCoordinates;
    }
  }

  // if route pin is true, get the cleaned line and set the tracker history to the cleaned line
  async function checkRoutePinning(coordinates) {
    if (routePin) {
      let cleaned = await getCleanedLine(coordinates, "driving");

      //create geoJson object for tracker history

      let trackerHistory = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: cleaned,
            },
          },
        ],
      };

      setTrackerHistory(trackerHistory);
    } else {
      let trackerHistory = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: coordinates,
            },
          },
        ],
      };

      setTrackerHistory(trackerHistory);
    }
  }

  // when tracker history changes, draw the line and setup the markers. if follow tracker is true, move the map to the current position
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (props.cleanType) {
        setCleanType(props.cleanType);
      }
      if (props.trackerHistory && props.trackerHistory.length > 0) {
        var coordinates = props.trackerHistory.map((uplink) => {
          return minMaxLngLat([uplink?.longitude || 0, uplink?.latitude || 0]);
        });

        // convert tracker history to circles to mark on history line
        let markers = [];

        props.trackerHistory.map((uplink) => {
          let marker = {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: minMaxLngLat([
                uplink.longitude || 0,
                uplink.latitude || 0,
              ]),
            },
          };
          markers.push(marker);
          return null;
        });

        //  geoJson to hold all of our markers
        let markerCollection = {
          type: "FeatureCollection",
          features: markers,
        };

        setTrackerHistoryMarkers(markerCollection);

        checkRoutePinning(coordinates);

        if (followTracker) {
          props.updateMap(props.trackerHistory.length - 1);
        }
      }
    }
    return () => (mounted = false);
    // eslint-disable-next-line
  }, [props.trackerHistory]);

  //when current uplink is changed in history, recenter the map when viewing a tracker
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (mapRef.current) {
        if (props.deviceTracking) {
          if (props.current) {
            mapRef.current.flyTo({
              center: minMaxLngLat([
                props?.trackerHistory[props?.current]?.longitude ||
                  minMaxLng(props.initialLatLng.lng),
                props?.trackerHistory[props?.current]?.latitude ||
                  minMaxLng(props.initialLatLng.lat),
              ]),
              maxDuration: 2000,
              essential: true,
              animated: false,
              bearing: 0,
              pitch: pitch,
            });
          }
        }
      }
    }
    return () => (mounted = false);
    // eslint-disable-next-line
  }, [props.current, mapRef, props.trackers]);

  //update lines on map when props.selectedHotspots changes
  useEffect(() => {
    setShowHotspotPopup(false);
    let mounted = true;
    if (mounted) {
      if (props.deviceTracking && mapRef.current) {
        if (props.selectedHotspots && props.selectedHotspots.length > 0) {
          let lines = [];
          props.selectedHotspots.map((hotspot) => {
            if (
              hotspot?.lat &&
              props.markerPosition &&
              props.markerPosition?.lat
            ) {
              let line = {
                type: "Feature",
                geometry: {
                  type: "LineString",
                  coordinates: [
                    minMaxLngLat([
                      props.markerPosition.lng,
                      props.markerPosition.lat,
                    ]),
                    minMaxLngLat([hotspot.long, hotspot.lat]),
                  ],
                },
              };
              lines.push(line);
            }
            return null;
          });

          //  geoJson to hold all of our lines
          let lineCollection = {
            type: "FeatureCollection",
            features: lines,
          };

          setHotspotsToCurrentPosition(lineCollection);
        }
      }
    }
    return () => (mounted = false);
  }, [
    mapRef,
    props.selectedHotspots,
    props.hotspots,
    props.markerPosition,
    props.deviceTracking,
  ]);

  //useEffect setup camera position
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (mapRef.current) {
        if (props.deviceTracking) {
          if (props.trackerHistory && props.trackerHistory.length === 0) {
            mapRef.current.flyTo({
              center: minMaxLngLat([
                props.initialLatLng.lng,
                props.initialLatLng.lat,
              ]),
              maxDuration: 2000,
              essential: true,
              animated: false,
              bearing: 0,
              pitch: 0,
              //max zoom
              zoom: 2,
            });
          } else {
            mapRef.current.flyTo({
              center: minMaxLngLat([
                props.initialLatLng.lng || 0,
                props.initialLatLng.lat || 0,
              ]),
              maxDuration: 2000,
              essential: true,
              animated: false,
              bearing: 0,
              pitch: pitch,
              zoom: zoom,
            });
          }
        } else {
          //fit bounds of trackers
          if (props.trackers && props.trackers.length > 0) {
            var bounds = new mapboxgl.LngLatBounds();
            props.trackers.map((tracker) => {
              if (
                tracker.last_location &&
                tracker.last_location.latitude &&
                tracker.last_location.longitude
              ) {
                bounds.extend(
                  minMaxLngLat([
                    tracker.last_location.longitude,
                    tracker.last_location.latitude,
                  ])
                );
              }
              return null;
            });

            //check if bounds are valid
            if (bounds.getNorth() !== -Infinity) {
              mapRef.current.fitBounds(bounds, {
                padding: 50,
                animate: false,
              });
            }
          }
        }
      }
    }
    return () => (mounted = false);
    // eslint-disable-next-line
  }, [mapRef, props.initialLatLng, props.trackers, props.deviceTracking]);

  return (
    <>
      <Map
        className="map-container"
        ref={mapRef}
        projection="globe"
        style={{ width: "100vw", height: "70vh" }}
        mapStyle={
          props.mode === "dark"
            ? "mapbox://styles/bfgneil/cle2sqoh8003s01ogafzo3xsl"
            : "mapbox://styles/bfgneil/cldvk4zf8003a01pl7mvq24du"
        }
        onViewportChange={(viewport) => {
          setZoom(viewport.zoom);
          setPitch(viewport.pitch);
          setBearing(viewport.bearing);
        }}
        onLoad={(map) => {
          addButtons(mapRef.current);
        }}
      >
        <NavigationControl position="top-left" />
        <FullscreenControl position="top-left" />
        <ScaleControl position="bottom-right" />
        {
          // check if its more than one tracker and show all last_locations
          !props.deviceTracking &&
            props.trackers &&
            props.trackers.map((tracker) => {
              if (
                tracker.last_location &&
                tracker.last_location.latitude &&
                tracker.last_location.longitude
              ) {
                var trackerDetails = minMaxLngLat([
                  tracker.last_location.longitude,
                  tracker.last_location.latitude,
                ]);

                return (
                  tracker?.last_location &&
                  trackerDetails[0] && (
                    <Marker
                      key={tracker.id}
                      latitude={minMaxLat(trackerDetails[1])}
                      longitude={minMaxLng(trackerDetails[0])}
                      onClick={() => {
                        setTrackerData(tracker);
                        setShowTrackerPopup(true);
                      }}
                    >
                      <img
                        src={tracker?.image_url || "/tracker-placeholder.jpeg"}
                        width="40"
                        height="40"
                        className="tracker-icon"
                        alt={tracker.name}
                      />
                    </Marker>
                  )
                );
              } else {
                return null;
              }
            })
        }
        {
          // if there is no tracker history, show no data popup
          props.deviceTracking && props.trackerHistory?.length === 0 && (
            <Popup
              latitude={minMaxLat(props?.initialLatLng?.lat || 0)}
              longitude={minMaxLng(props?.initialLatLng?.lng || 0)}
              closeButton={false}
              closeOnClick={false}
              anchor="top"
            >
              <div className="no-data-popup">
                <h3 className="text-dark">No Data Available</h3>
                <p className="text-dark">
                  This tracker has not sent any data to Trackpac for the
                  selected dates. <br />
                  <br />
                  Please select a different date range or restart / check charge
                  the tracker.
                </p>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    window.location.href = "/tracker";
                  }}
                >
                  View Trackers
                </Button>
              </div>
            </Popup>
          )
        }

        {
          //tracker History circle markers
          props.deviceTracking &&
            props.trackerHistory &&
            props.trackerHistory.length > 0 &&
            props.trackerHistory.map((tracker, index) => {
              var trackerDetails = minMaxLngLat([
                tracker.longitude,
                tracker.latitude,
              ]);

              return (
                <Marker
                  key={tracker.id}
                  latitude={minMaxLat(trackerDetails[1])}
                  longitude={minMaxLng(trackerDetails[0])}
                  onClick={() => {
                    props.setCurrent(index);
                    setMarkerData(tracker);
                    setShowMarkerPopup(true);
                    setShowHotspotPopup(false);
                  }}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="10"
                      cy="10"
                      r="10"
                      fill={theme.palette.primary.main}
                      fillOpacity="0.7"
                    />
                  </svg>
                </Marker>
              );
            })
        }
        {
          // pollyline for tracker history
          props.deviceTracking &&
            props.trackerHistory &&
            props.trackerHistory.length > 0 && (
              <Source type="geojson" data={trackerHistory}>
                <Layer {...trackerHistoryLayer} />
              </Source>
            )
        }
        {
          //plot geofences for tracker on deviceTracking mode
          props.deviceTracking && props.geofences && (
            <Source
              type="geojson"
              data={createGeoJSONCircleCollection(props.geofences)}
            >
              <Layer {...geofenceLayer} />
            </Source>
          )
        }
        {
          // show popup when hotspot is clicked
          props.deviceTracking && showHotspotPopup && hotspotData !== null && (
            <Popup
              key={hotspotData.id + "-popup"}
              latitude={minMaxLat(hotspotData?.lat || 0)}
              longitude={minMaxLng(hotspotData?.long || 0)}
              closeButton={true}
              closeOnClick={false}
              onClose={() => setShowHotspotPopup(false)}
              offset={[0, -20]}
            >
              <div>
                <h2 className="text-dark">
                  {
                    animalHash(hotspotData.id) //format hotspot address to animal hash
                  }
                </h2>

                <p className="text-dark">
                  Signal Strength: {hotspotData.rssi} RSSI <br />
                  SNR:{" "}
                  {Math.round((hotspotData.snr + Number.EPSILON) * 100) / 100}
                  {" dB"}
                  <br />
                </p>
                <a
                  href={
                    "https://explorer.helium.com/hotspots/" + hotspotData.id
                  }
                  rel="noreferrer"
                  target="_blank"
                  className="btn btn-primary"
                >
                  View on Helium Explorer
                </a>
              </div>
            </Popup>
          )
        }
        {
          // show popup when Marker is clicked
          props.deviceTracking && showMarkerPopup && (
            <Popup
              key={markerData.id + "-popup"}
              latitude={markerData?.latitude || 0}
              longitude={markerData?.longitude || 0}
              closeButton={true}
              closeOnClick={false}
              onClose={() => setShowMarkerPopup(false)}
              offset={[0, -40]}
            >
              <div>
                {
                  // format date to local time in h2
                }
                <h2 className="text-dark">
                  {moment(markerData.time).tz(tz).format("lll")}
                </h2>
                <p className="text-dark">{markerData.geocode}</p>

                <p className="text-dark">
                  Heard By: {markerData.received_by.length} hotspot
                  {markerData.received_by.length > 1 ? "s" : ""}
                </p>
                <p className="text-dark">Accuracy: {markerData.accuracy}m</p>
                <p className="text-dark">Battery: {markerData.battery}%</p>
                <p className="text-dark">
                  Estimated Speed:{" "}
                  {getSpeed(
                    props.trackerHistory[props.current - 1],
                    props.trackerHistory[props.current]
                  )}
                  {props.userMeasurementUnit === "metric" ? "km/h" : "mph"}
                </p>
                <Button
                  onClick={() => {
                    handleLocationOptions(markerData);
                  }}
                  variant="outlined"
                  startIcon={<OpenInNewIcon />}
                >
                  Options
                </Button>
              </div>
            </Popup>
          )
        }
        {
          // show popup when tracker is clicked
          !props.deviceTracking && showTrackerPopup && (
            <Popup
              key={trackerData.id + "-popup"}
              latitude={trackerData?.last_location?.latitude || 0}
              longitude={trackerData?.last_location?.longitude || 0}
              closeButton={true}
              closeOnClick={false}
              onClose={() => setShowTrackerPopup(false)}
              offset={[0, -20]}
              anchor="top"
            >
              <div>
                <h1 className="text-dark">{trackerData.name}</h1>
                {
                  // format date to local time in h2
                }
                <p className="text-dark">
                  Last Update: {moment(trackerData.time).tz(tz).format("lll")}
                </p>
                <p className="text-dark">
                  Last Location: {trackerData?.last_location?.geocode || "N/A"}
                </p>
                <Button
                  onClick={() => {
                    //redirect to tracker page
                    window.location.href = "/tracker/" + trackerData.id;
                  }}
                  variant="outlined"
                >
                  View Tracker
                </Button>
              </div>
            </Popup>
          )
        }
        {
          // Plot hotspots on map for helium enthusiast mode
          props.deviceTracking &&
            props.heliumEnthusiastMode &&
            props.selectedHotspots.map((hotspot) => {
              if (hotspot?.lat) {
                return (
                  <>
                    <Marker
                      key={hotspot.id + "-marker"}
                      latitude={hotspot?.lat || 0}
                      longitude={hotspot.long || 0}
                      onClick={async () => {
                        setHotspotData(hotspot);
                        setShowHotspotPopup(true);
                        //pan to hotspot
                        mapRef.current?.panTo({
                          lat: hotspot.lat,
                          lng: hotspot.long,
                        });
                      }}
                    >
                      <img
                        key={hotspot.id + "img"}
                        src="/hotspot.png"
                        width="30"
                        height="30"
                        className="hotspot-icon"
                        alt={hotspot.name}
                      />
                    </Marker>
                  </>
                );
              } else {
                return null;
              }
            })
        }
        {
          //plot hotspots lines for helium enthusiast mode
          props.deviceTracking && props.heliumEnthusiastMode && (
            <Source type="geojson" data={hotspotsToCurrentPosition}>
              <Layer {...hotspotsToCurrentPositionLayer} />
            </Source>
          )
        }
        {
          //add playback control to map
          props.deviceTracking && props.trackerHistory && (
            <>
              {
                // detect if mapbox is fullscreen and show history playback control
                !isMobile && (
                  <HistoryPlaybackControl
                    mapRef={mapRef}
                    trackerHistory={props.trackerHistory}
                    updateMap={props.updateMap}
                    current={props.current}
                    startPlayback={props.startPlayback}
                    stopPlayback={props.stopPlayback}
                    setCurrent={props.setCurrent}
                    restartPlayback={props.restartPlayback}
                    heliumEnthusiastMode={props.heliumEnthusiastMode}
                    hotspots={props.hotspots}
                    userMeasurementUnit={props.userMeasurementUnit}
                  />
                )
              }
            </>
          )
        }
        {
          // show marker for current location of tracker in history timeline
          props.deviceTracking &&
            props.markerPosition &&
            props.markerPosition.lat !== 0 && (
              <Marker
                key={props?.trackers[0]?.id}
                latitude={minMaxLat(props?.markerPosition?.lat || 0)}
                longitude={minMaxLng(props?.markerPosition?.lng || 0)}
                onClick={() => {
                  setMarkerData(props.trackerHistory[props.current]);
                  setShowMarkerPopup(true);
                  //pan to marker

                  mapRef.current.flyTo({
                    center: minMaxLngLat([
                      props.markerPosition.lng,
                      props.markerPosition.lat,
                    ]),
                    maxDuration: 1000,
                    essential: true,
                  });
                }}
              >
                <img
                  src={
                    props.trackers[0]?.image_url || "/tracker-placeholder.jpeg"
                  }
                  width="40"
                  height="40"
                  className="tracker-icon"
                  alt={props?.trackers[0]?.name}
                />
              </Marker>
            )
        }
      </Map>
      <DateTimePickerDialog
        open={openDateTimePicker}
        onClose={() => setOpenDateTimePicker(false)}
        trackerStartDate={props.trackerStartDate}
        trackerEndDate={props.trackerEndDate}
        setTrackerStartDate={props.setTrackerStartDate}
        setTrackerEndDate={props.setTrackerEndDate}
      />
      <LocationOptionsDialog
        location={location}
        handleLocationOptions={handleLocationOptions}
        showLocationOptions={showLocationOptions}
        trackers={props.trackers}
      />
    </>
  );
}
