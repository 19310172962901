import mapboxgl from "mapbox-gl";
import { getAuthHeader } from "../auth";
import { CONFIG } from "../config";
import axios from "axios";
import addImages from "./addImages";
import formatDate from "./formatDate";

//mapbox access token
mapboxgl.accessToken =
  "pk.eyJ1IjoiYmZnbmVpbCIsImEiOiJjbGR2azExeW4wZ28yM29tZm4xZjJwdHFxIn0.Mdv--BkZwKSw-pdTRMQdPg";

async function fetchTrackers() {
  try {
    const headers = await getAuthHeader();
    const apiUrl = CONFIG.API_URL + "/devices";
    const res = await axios.get(apiUrl, { headers: headers });

    return res.data;
  } catch (e) {
    console.log(e);
  }
}

async function addTrackers(mapRef) {
  const trackers = await fetchTrackers();
  //remove any trackers missing last_location and last_location longitude and latitude
  let cleanedTrackers = {};

  //remove any trackers missing last_location and last_location longitude and latitude
  if (trackers && trackers.length) {
    cleanedTrackers = trackers.filter(
      (tracker) =>
        tracker.last_location &&
        tracker.last_location.longitude &&
        tracker.last_location.latitude
    );
  }

  let geojson = {
    type: "FeatureCollection",
    features: [],
  };
  //map trackers to geojson
  if (cleanedTrackers && cleanedTrackers.length) {
    geojson = {
      type: "FeatureCollection",
      features: cleanedTrackers.map((tracker) => {
        return {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [
              tracker.last_location.longitude,
              tracker.last_location.latitude,
            ],
          },
          properties: {
            id: tracker.id,
            title: tracker.name,
            geocode: tracker.last_location.geocode,
            time: formatDate(tracker.last_location.time),
            image: "marker-15",
          },
        };
      }),
    };
  }
  if (!mapRef.getSource("trackers-source")) {
    // add tracker source and layer
    mapRef.addSource("trackers-source", {
      type: "geojson",
      data: geojson,
    });

    //add layer with tracker images
    mapRef.addLayer({
      id: "trackers-layer",
      type: "symbol",
      source: "trackers-source",
      layout: {
        "icon-image": "{image}",
        "icon-size": 1,
      },
    });
  }
  //if trackers length = 1, fly to that tracker if not get bounds of all trackers and fit map to bounds, if empty set map to default location
  if (cleanedTrackers && cleanedTrackers.length === 1) {
    mapRef.flyTo({
      center: [
        trackers[0].last_location.longitude,
        trackers[0].last_location.latitude,
      ],
      zoom: 12,
    });
  }
  if (cleanedTrackers && cleanedTrackers.length > 1) {
    const bounds = new mapboxgl.LngLatBounds();
    cleanedTrackers.forEach((tracker) => {
      //check logitude and latitude are within valid range
      if (
        tracker.last_location.longitude > -180 &&
        tracker.last_location.longitude < 180 &&
        tracker.last_location.latitude > -90 &&
        tracker.last_location.latitude < 90
      ) {
        bounds.extend([
          tracker.last_location.longitude,
          tracker.last_location.latitude,
        ]);
      }
    });
    mapRef.fitBounds(bounds, {
      padding: 50,
      animate: false,
      duration: 0,
      essential: true,
    });
  }
}

// create map function
export default async function createTrackerMap(mapRef) {
  //if map already exists, return
  if (mapRef.current) return;

  mapRef.current = new mapboxgl.Map({
    container: "mapPanel",
    style: "mapbox://styles/bfgneil/cle2sqoh8003s01ogafzo3xsl?optimize=true",
  });

  addImages(mapRef.current);

  mapRef.current.on("load", function () {
    addTrackers(mapRef.current);
  });

  mapRef.current.on("click", "trackers-layer", function (e) {
    // on click new popup with tracker info
    new mapboxgl.Popup()
      .setLngLat(e.features[0].geometry.coordinates)
      .setHTML(
        `<h3 class='text-dark'>${e.features[0].properties.title}</h3>
        <p class='text-dark'>Last Location: ` +
          e.features[0].properties.geocode +
          `</p><p class='text-dark'>Last Updated: ` +
          e.features[0].properties.time +
          `</p><a class="view-button" href="/tracker/` +
          e.features[0].properties.id +
          `">View</a>`
      )
      .addTo(mapRef.current);
  });
}
