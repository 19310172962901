import mapboxgl from "mapbox-gl";
import updateHotspotsSource from "./updateHotspotSource";
import formatDate from "./formatDate";
import moment from "moment";
import { minMaxLngLat } from "./minMaxLngLat";

//mapbox access token
mapboxgl.accessToken =
  "pk.eyJ1IjoiYmZnbmVpbCIsImEiOiJjbGR2azExeW4wZ28yM29tZm4xZjJwdHFxIn0.Mdv--BkZwKSw-pdTRMQdPg";

// add a pulsing marker for current location
export default function addCurrentLocation(history, mapRef, current = 0) {
  const size = 200;

  let historyObject = history;

  //return if no history
  if (!history || history.length === 0) {
    historyObject = [
      {
        longitude: 0,
        latitude: 0,
        geocode: "Unknown",
        time: moment().format("YYYY-MM-DD HH:mm:ss"),
        received_by: [],
      },
    ];
  }

  const pulsingDot = {
    width: size,
    height: size,
    data: new Uint8Array(size * size * 4),

    // When the layer is added to the map,
    // get the rendering context for the map canvas.
    onAdd: function () {
      const canvas = document.createElement("canvas");
      canvas.width = this.width;
      canvas.height = this.height;
      this.context = canvas.getContext("2d");
    },

    // Call once before every frame where the icon will be used.
    render: function () {
      const duration = 3000;
      const t = (performance.now() % duration) / duration;

      const radius = (size / 3) * 0.3;
      const outerRadius = (size / 3) * 0.7 * t + radius;
      const context = this.context;

      // Draw the outer circle.
      context.clearRect(0, 0, this.width, this.height);
      context.beginPath();
      context.arc(this.width / 2, this.height / 2, outerRadius, 0, Math.PI * 2);
      context.fillStyle = `rgba(0, 208, 159, ${1 - t})`;
      context.fill();

      // Draw the inner circle.
      context.beginPath();
      context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
      context.fillStyle = "rgba(0, 208, 159)";
      context.strokeStyle = "#004d40";
      context.lineWidth = 2 + 4 * (1 - t);
      context.fill();
      context.stroke();

      // Update this image's data with data from the canvas.
      this.data = context.getImageData(0, 0, this.width, this.height).data;

      // Continuously repaint the map, resulting
      // in the smooth animation of the dot.
      mapRef.triggerRepaint();

      // Return `true` to let the map know that the image was updated.
      return true;
    },
  };

  if (!mapRef.getSource("dot-point")) {
    mapRef.addSource("dot-point", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: minMaxLngLat([
                historyObject[0].longitude,
                historyObject[0].latitude,
              ]),
            },
            properties: {
              title: historyObject[0].geocode,
              time: historyObject[0].time,
              hotspots: historyObject[0].received_by,
              index: 0,
            },
          },
        ],
      },
    });

    mapRef.addImage("pulsing-dot", pulsingDot, { pixelRatio: 2 });

    mapRef.addLayer({
      id: "layer-with-pulsing-dot",
      type: "symbol",
      source: "dot-point",
      layout: {
        "icon-image": "pulsing-dot",
      },
    });

    //onclick of dot-point, fly to location and show popup
    mapRef.on("click", "layer-with-pulsing-dot", function (e) {
      mapRef.flyTo({
        center: e.features[0].geometry.coordinates,
        maxDuration: 2000,
        essential: true,
        animated: false,
      });

      updateHotspotsSource(
        e.features[0].properties.hotspots,
        mapRef,
        historyObject,
        e.features[0].properties.index
      );

      new mapboxgl.Popup()
        .setLngLat(e.features[0].geometry.coordinates)
        .setHTML(
          "<h3 class='text-dark'>" +
            formatDate(e.features[0].properties.time) +
            "</h3><p class='text-dark'><strong>Location:</strong><br /> " +
            e.features[0].properties.title +
            "</p>"
        )
        .addTo(mapRef);
    });
  } else {
    if (historyObject.length < current) return;

    mapRef.getSource("dot-point").setData({
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [
              historyObject[current].longitude,
              historyObject[current].latitude,
            ],
          },
          properties: {
            title: historyObject[current].geocode,
            time: historyObject[current].time,
            hotspots: historyObject[current].hotspots,
            index: current,
          },
        },
      ],
    });
  }
}
