import { BrowserRouter, Routes, Route } from "react-router-dom";
import { getAuthHeader } from "./auth";
import { Amplify } from "aws-amplify";
import { Authenticator, Image, useTheme, View } from "@aws-amplify/ui-react";
import awsExports from "./aws-exports";
import Homepage from "./containers/Homepage";
import Tracker from "./containers/Tracker";
import Settings from "./containers/Settings";
import TrackerBilling from "./containers/TrackerBilling";
import Assets from "./containers/Assets";
import Groups from "./containers/Groups";
import Alerts from "./containers/Alerts";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import getThemeOptions from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { useEffect, useState } from "react";
import Asset from "./containers/Asset";
import AddTracker from "./containers/TrackerAdd";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { CONFIG } from "./config";
import axios from "axios";

import "./amplify.css";
import BillingSuccess from "./containers/BillingSuccess";
import Layout from "./components/Layout";
//import VideoMaker from "./containers/VideoMaker";

export default function App() {
  Amplify.configure(awsExports);
  // eslint-disable-next-line
  const [mode, setMode] = useState(localStorage.getItem("mode") || "light");

  async function setWhitelabel() {
    const whitelabel = {
      whitelabel: CONFIG.whitelabel,
    };

    const headers = await getAuthHeader();

    await axios
      .post(CONFIG.API_URL + "/whitelabel", whitelabel, { headers: headers })
      .catch((error) => {});
  }

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setWhitelabel();
      if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
        setMode("dark");
      }
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .addEventListener("change", (event) => {
          const colorScheme = event.matches ? "dark" : "light";
          setMode(colorScheme);
        });
    }
    return () => (mounted = false);
  }, []);

  const components = {
    Header() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Image alt="Trackpac" src="/logo.png" width="60%" />
        </View>
      );
    },
  };

  const handleThemeChange = () => {
    if (localStorage.getItem("mode") === "dark") {
      localStorage.setItem("mode", "light");
      setMode("light");
    } else {
      localStorage.setItem("mode", "dark");
      setMode("dark");
    }
  };

  return (
    <Authenticator loginMechanisms={["email"]} components={components}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={getThemeOptions(mode)}>
          <CssBaseline />

          <BrowserRouter>
            <Layout themeMode={mode} handleThemeChange={handleThemeChange}>
              <Routes>
                <Route path="/" element={<Homepage mode={mode} />} />
                <Route path="/tracker" element={<Homepage mode={mode} />} />
                <Route path="/tracker/add" element={<AddTracker />} />
                <Route path="/tracker/:id" element={<Tracker mode={mode} />} />
                <Route
                  path="/tracker/:id/billing"
                  element={<TrackerBilling />}
                />
                <Route
                  path="/tracker/:id/billing/:activateEarly"
                  element={<TrackerBilling />}
                />
                <Route path="/asset" element={<Assets />} />
                <Route path="/asset/:id" element={<Asset />} />
                <Route path="/alert" element={<Alerts />} />
                <Route path="/user" element={<Groups />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/billing/success" element={<BillingSuccess />} />
                {
                  // <Route path="/video-maker" element={<VideoMaker />} />
                }
              </Routes>
            </Layout>
          </BrowserRouter>
        </ThemeProvider>
      </LocalizationProvider>
    </Authenticator>
  );
}
