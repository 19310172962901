import React, { useEffect } from "react";
import { getAuthHeader } from "../../auth";
import { CONFIG } from "../../config";
import axios from "axios";
import { Auth } from "aws-amplify";
import createTrackerMap from "../../Functions/createTrackerMap";
import { Box } from "@mui/material";

import TrackerListTable from "../../components/TrackerList";

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export default function Homepage(props) {
  document.title = CONFIG.site_title + " Tracker Management Dashboard";

  const mapRef = React.useRef(null);

  async function setUserIdentityInfo() {
    let headers = await getAuthHeader();
    headers["content-type"] = "application/json";
    const apiUrl = CONFIG.API_URL + "/user";

    const user = await axios.get(apiUrl, { headers: headers });

    if (user.data.username && !validateEmail(user.data.username)) {
      const userInfo = await Auth.currentAuthenticatedUser();
      const email = userInfo.attributes.email;
      const json_data = {
        email: email,
      };
      axios.post(apiUrl, JSON.stringify(json_data), {
        headers: headers,
      });
    }
  }

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setUserIdentityInfo();
      createTrackerMap(mapRef);

      return () => {
        mounted = false;
        if (mapRef.current) {
          mapRef.current.remove();
          mapRef.current = null;
        }
      };
    }
  }, []);

  return (
    <>
      <Box sx={{ py: 2 }}>
        <div id="mapPanel" />
      </Box>
      <Box sx={{ my: 2 }}>
        <TrackerListTable />
      </Box>
    </>
  );
}
