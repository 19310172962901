import {
  AppBar,
  Button,
  CssBaseline,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  ThemeProvider,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import HomeIcon from "@mui/icons-material/Home";
import { Auth } from "aws-amplify";
import { getAuthHeader } from "../../auth";
import { CONFIG } from "../../config";
import { useNavigate } from "react-router-dom";
import { Folder, People, Radar, WarningAmber } from "@mui/icons-material";
import { useEffect, useState } from "react";
import axios from "axios";

export default function Layout2(props) {
  const [trackers, setTrackers] = useState([]);
  const theme = useTheme();
  const navigate = useNavigate();

  const handleRedirect = (event) => {
    window.location.replace("/tracker/" + event.target.value);
  };

  async function signOut() {
    try {
      await Auth.signOut();
      navigate("/");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  useEffect(() => {
    getDevices();
  }, []);

  async function getDevices() {
    const headers = await getAuthHeader();
    const apiUrl = CONFIG.API_URL + "/devices";
    const res = await axios.get(apiUrl, { headers: headers });

    if (res?.data.length > 0) {
      setTrackers(res.data);
    }
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div>
          <Grid container>
            <Grid item xs={12}>
              <AppBar position="static" className="main-navigation-bar">
                <Toolbar>
                  <div style={{ flexGrow: 1 }}>
                    <Typography
                      variant="h6"
                      className="siteTitle"
                      sx={{ color: "white" }}
                      onClick={() => navigate("/")}
                    >
                      {CONFIG.site_title}
                    </Typography>
                  </div>
                  <FormControl
                    sx={{ m: 1, minWidth: 120 }}
                    size="small"
                    className="select-tracker-form"
                  >
                    <InputLabel id="tracker" sx={{ color: "#FFF" }}>
                      Tracker
                    </InputLabel>
                    <Select
                      labelId="tracker"
                      className="selectTracker"
                      id="tracker"
                      value={props.selectedTracker || ""}
                      label="Tracker"
                      onChange={handleRedirect}
                      variant="outlined"
                      sx={{
                        ".MuiSelect-icon": {
                          color: "white",
                        },
                        ".MuiSelect-outlined": {
                          color: "white",
                        },
                      }}
                    >
                      {trackers.map((tracker) => (
                        <MenuItem key={tracker.id} value={tracker.id}>
                          {tracker.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <Button color="inherit" onClick={signOut}>
                    Logout
                  </Button>
                </Toolbar>
              </AppBar>
            </Grid>
            <Grid item xs={5} sm={3} md={2} lg={2}>
              <List>
                <ListItem disablePadding onClick={() => navigate("/")}>
                  <ListItemButton>
                    <ListItemIcon>
                      <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Home" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding onClick={() => navigate("/tracker")}>
                  <ListItemButton>
                    <ListItemIcon>
                      <Radar />
                    </ListItemIcon>
                    <ListItemText primary="Trackers" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding onClick={() => navigate("/alert")}>
                  <ListItemButton>
                    <ListItemIcon>
                      <WarningAmber />
                    </ListItemIcon>
                    <ListItemText primary="Alerts" />
                  </ListItemButton>
                </ListItem>

                <ListItem disablePadding onClick={() => navigate("/settings")}>
                  <ListItemButton>
                    <ListItemIcon>
                      <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Account" />
                  </ListItemButton>
                </ListItem>
              </List>
            </Grid>
            <Grid
              item
              xs={7}
              sm={9}
              md={10}
              lg={10}
              container
              direction="column"
              className="contentArea"
              style={theme.contentArea}
            >
              {props.children}
            </Grid>
          </Grid>
          <footer className="footer-bar p-3">
            <Typography variant="h6" align="center">
              {CONFIG.site_title}
            </Typography>
          </footer>
        </div>
      </ThemeProvider>
    </>
  );
}
