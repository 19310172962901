import hotspot from "../hotspot.png";

export default function addImages(map) {
  //add image for location history marker
  map.loadImage(
    "https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png",
    function (error, image) {
      if (error) throw error;
      map.addImage("marker-15", image);
    }
  );

  //add image for hotspot-icon from local source
  map.loadImage(hotspot, (error, image) => {
    if (error) throw error;
    map.addImage("hotspot-icon", image);
  });
}
