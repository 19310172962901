import {
  Dialog,
  DialogTitle,
  FormControl,
  InputLabel,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  Button,
  DialogActions,
  DialogContent,
  Slide,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { getAuthHeader } from "../../auth";
import { CONFIG } from "../../config";
import axios from "axios";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AttachFileIcon from "@mui/icons-material/AttachFile";

TrackerSettingsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function TrackerSettingsDialog(props) {
  const [userDB, setUserDB] = React.useState();
  const { onClose, open } = props;
  const [trackerName, setTrackerName] = useState();
  const [trackerImage, setTrackerImage] = useState();
  const [preview, setPreview] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [trackerBatteryAlertLevel, setTrackerBatteryAlertLevel] = useState();
  // eslint-disable-next-line
  const [trackerIdleCheckinFrequency, setTrackerIdleCheckinFrequency] =
    useState();
  // eslint-disable-next-line
  const [trackerMovingCheckinFrequency, setTrackerMovingCheckinFrequency] =
    useState();

  const handleClose = () => {
    onClose();
  };

  const saveSettings = async () => {
    var apiUrl = CONFIG.API_URL + "/devices/" + props.tracker.id;

    const data = {
      name: trackerName,
      icon: props.tracker.icon,
      battery_alert_percent: trackerBatteryAlertLevel,
    };

    const headers = await getAuthHeader();
    await axios.put(apiUrl, data, { headers: headers });

    if (selectedFile) {
      apiUrl = CONFIG.API_URL + "/devices/" + props.tracker.id + "/image";

      const dataImage = {
        base64_image: selectedFile,
      };

      await axios.post(apiUrl, dataImage, { headers: headers });
    }

    alert("Settings saved!");
    props.getTracker();
    onClose();
  };

  async function getUser() {
    const authHeader = await getAuthHeader();
    const response = await axios.get(CONFIG.API_URL + "/user", {
      headers: authHeader,
    });
    setUserDB(response.data);
  }

  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      getUser();
      setTrackerName(props?.tracker?.name);
      setTrackerBatteryAlertLevel(props?.tracker?.battery_alert_percent);
      setTrackerImage(props?.tracker?.image_url);
      setTrackerIdleCheckinFrequency(props?.tracker?.checkin_frequency_idle);
      setTrackerMovingCheckinFrequency(
        props?.tracker?.checkin_frequency_moving
      );
      return () => (mounted = false);
    }
  }, [props.tracker]);

  function onFileChange(event) {
    const objectUrl = URL.createObjectURL(event.target.files[0]);
    setPreview(objectUrl);

    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = (e) => {
      setSelectedFile(e.target.result);
    };
  }

  async function checkBillingStatus() {
    if (props?.tracker?.billing_status === "trial") {
      window.location.replace(
        "/tracker/" + props?.tracker?.id + "/billing/activate-early"
      );
    }
    if (props?.tracker?.billing_status === "active") {
      const headers = await getAuthHeader();
      const response = await axios.get(
        CONFIG.API_URL + "/billing/portal-session",
        {
          headers: headers,
        }
      );

      window.location.replace(response.data.url);
    }
  }

  return (
    <Dialog
      TransitionComponent={Transition}
      onClose={handleClose}
      open={open}
      className="settingsDialog"
    >
      <DialogTitle>Settings for {props?.tracker?.name}</DialogTitle>
      <DialogContent>
        {trackerImage && !preview && (
          <img
            src={trackerImage}
            alt={props?.tracker?.name}
            width="120"
            height="120"
            class="tracker-icon"
          />
        )}
        {preview && (
          <img
            src={preview}
            alt={props?.tracker?.name}
            width="120"
            height="120"
            class="tracker-icon"
          />
        )}
        {!preview && !trackerImage && (
          <img
            src="/tracker-placeholder.jpeg"
            alt={props?.tracker?.name}
            width="120"
            height="120"
            class="tracker-icon"
          />
        )}
        {
          <FormControl>
            <IconButton color="primary" component="label">
              <input
                type="file"
                accept="image/*"
                hidden
                onChange={onFileChange}
              />
              <AttachFileIcon fontSize="medium" />
            </IconButton>
          </FormControl>
        }
        <FormControl fullWidth className="mt-3">
          <TextField
            variant="outlined"
            id="name"
            placeholder="Name"
            label="Name"
            value={trackerName}
            onChange={(e) => setTrackerName(e.target.value)}
          />
        </FormControl>
        <FormControl fullWidth className="mt-3">
          <TextField
            variant="outlined"
            select
            id="battery_alert_percent"
            label="Warn me when battery is at"
            value={trackerBatteryAlertLevel}
            onChange={(e) => setTrackerBatteryAlertLevel(e.target.value)}
          >
            <MenuItem value={10}>5%</MenuItem>
            <MenuItem value={10}>10%</MenuItem>
            <MenuItem value={15}>15%</MenuItem>
            <MenuItem value={20}>20%</MenuItem>
            <MenuItem value={25}>25%</MenuItem>
            <MenuItem value={30}>30%</MenuItem>
            <MenuItem value={35}>35%</MenuItem>
            <MenuItem value={40}>40%</MenuItem>
            <MenuItem value={45}>45%</MenuItem>
            <MenuItem value={50}>50%</MenuItem>
            <MenuItem value={55}>55%</MenuItem>
            <MenuItem value={60}>60%</MenuItem>
            <MenuItem value={65}>65%</MenuItem>
            <MenuItem value={70}>70%</MenuItem>
          </TextField>
        </FormControl>

        {userDB?.whitelabel?.custom_billing !== true && (
          <FormControl
            fullWidth
            className="pt-3 pb-3 px-2"
            sx={{
              display: "flex",
              flexDirection: "row",
              borderRadius: "4px",
            }}
          >
            <div style={{ flexGrow: 1 }}>
              <Typography className="pt-2">Billing Status</Typography>
            </div>
            <div sx={{ flex: 1 }}>
              <Button
                variant="outlined"
                onClick={checkBillingStatus}
                startIcon={<OpenInNewIcon />}
              >
                {`${props?.tracker?.billing_status}`}
              </Button>
            </div>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="error"
          onClick={handleClose}
          style={{ marginLeft: "auto" }}
        >
          Cancel
        </Button>
        <Button variant="outlined" onClick={saveSettings}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
