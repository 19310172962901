import axios from "axios";
import { getAuthHeader } from "../auth";
import { CONFIG } from "../config";

export default async function getUser() {
  try {
    let headers = await getAuthHeader();
    headers["content-type"] = "application/json";
    const apiUrl = CONFIG.API_URL + "/user";

    const user = await axios.get(apiUrl, { headers: headers });
    return user.data;
  } catch (error) {
    console.log(error);
  }
}
