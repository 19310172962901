import mapboxgl from "mapbox-gl";
import addImages from "./addImages";
import addHotspots from "./addHotspots";
import addPolyline from "./addPolyline";
import addHistoryMarkers from "./addHistoryMarkers";
import addCurrentLocation from "./addCurrentLocation";
import addGeofences from "./addGeofences";

//mapbox access token
mapboxgl.accessToken =
  "pk.eyJ1IjoiYmZnbmVpbCIsImEiOiJjbGR2azExeW4wZ28yM29tZm4xZjJwdHFxIn0.Mdv--BkZwKSw-pdTRMQdPg";

function minMaxLngLat(lngLat) {
  const minLng = -180;
  const maxLng = 180;
  const minLat = -85;
  const maxLat = 85;

  const lng = Math.max(minLng, Math.min(maxLng, lngLat[0]));
  const lat = Math.max(minLat, Math.min(maxLat, lngLat[1]));

  return [lng, lat];
}

// create map function
export default async function createMap(
  mapRef,
  history,
  pitch,
  initialLngLat,
  current,
  setCurrent,
  heliumEnthusiastMode = false,
  geofences,
  trackerMatchingSetting = false
) {
  if (!mapRef.current === null) return;

  let currentLocation = initialLngLat;

  if (currentLocation === null) {
    currentLocation = [0, 0];
  }

  mapRef.current = new mapboxgl.Map({
    container: "mapPanel",
    style: "mapbox://styles/bfgneil/cle2sqoh8003s01ogafzo3xsl?optimize=true",
    center: minMaxLngLat(currentLocation),
    zoom: 15,
    pitch: pitch,
  });

  setCurrent(history ? history.length - 1 : 0);

  addImages(mapRef.current);

  mapRef.current.on("load", function () {
    if (history && history.length > 0) {
      addGeofences(mapRef.current, geofences);
      if (heliumEnthusiastMode) {
        addHotspots(history[current].received_by, mapRef.current, history);
      }
      addPolyline(history, mapRef.current, trackerMatchingSetting);
      addHistoryMarkers(history, mapRef.current, setCurrent);
      addCurrentLocation(history, mapRef.current, current);

      let bounds = new mapboxgl.LngLatBounds();

      history.forEach((point) => {
        bounds.extend(minMaxLngLat([point.longitude, point.latitude]));
      });

      mapRef.current.fitBounds(bounds, {
        padding: 50,
        maxZoom: 20,
      });
    } else {
      addGeofences(mapRef.current, []);
      if (heliumEnthusiastMode) {
        addHotspots([], mapRef.current, []);
      }
      addPolyline([], mapRef.current, trackerMatchingSetting);
      addHistoryMarkers([], mapRef.current, setCurrent);
      addCurrentLocation([], mapRef.current);
    }
  });
}
