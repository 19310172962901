import {
  FormControl,
  Button,
  Stack,
  Slide,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import React, { useState } from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DateTimePickerDialog(props) {
  const {
    onClose,
    open,
    trackerStartDate,
    trackerEndDate,
    setTrackerStartDate,
    setTrackerEndDate,
  } = props;
  const [startDate, setStartDate] = useState(trackerStartDate);
  const [endDate, setEndDate] = useState(trackerEndDate);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  window.addEventListener("resize", () => {
    if (window.innerWidth < 600) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  });

  const handleClose = () => {
    onClose();
  };

  function SetDateTime() {
    setTrackerStartDate(startDate);
    setTrackerEndDate(endDate);
    onClose();
  }

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      className="DateTimeDialog"
      // on mobile make it 80% width
      maxWidth={isMobile ? "false" : "sm"}
      fullScreen={isMobile}
      TransitionComponent={Transition}
    >
      <DialogTitle>Select Date & Time Range</DialogTitle>

      <DialogContent>
        <Typography className="mb-5">Date format is in MM/DD/YYYY</Typography>
        <Stack spacing={3}>
          <FormControl>
            <DateTimePicker
              label="Start Date"
              value={startDate}
              onChange={setStartDate}
              maxDate={new Date()}
              renderInput={(params) => <TextField {...params} />}
            />
          </FormControl>
          <FormControl>
            <DateTimePicker
              label="End Date"
              value={endDate}
              onChange={setEndDate}
              renderInput={(params) => <TextField {...params} />}
              maxDate={new Date()}
            />
          </FormControl>
        </Stack>
        <Stack
          spacing={3}
          direction={isMobile ? "column" : "row"}
          className="mt-5"
        >
          <Button
            variant="outlined"
            color="error"
            fullWidth={isMobile}
            onClick={handleClose}
            style={{ marginLeft: "auto" }}
          >
            Cancel
          </Button>
          <Button variant="outlined" onClick={SetDateTime} fullWidth={isMobile}>
            Set Date & Time
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
