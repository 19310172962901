import { Auth } from "aws-amplify";

export const getAuthHeader = async () => {
  const jwt = await Auth.currentSession().then((res) => {
    const accessToken = res.getAccessToken();
    const jwt = accessToken.getJwtToken();
    return jwt;
  });

  const headers = {
    authorization: "Bearer " + jwt,
  };
  return headers;
};
