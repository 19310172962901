export default function updateCurrentLocation(mapRef, historyItem, current) {
  if (!mapRef) return;
  let currentLocationSource = mapRef.getSource("dot-point");

  if (currentLocationSource) {
    currentLocationSource.setData({
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [historyItem.longitude, historyItem.latitude],
          },
          properties: {
            title: historyItem.geocode,
            time: historyItem.time,
            hotspots: historyItem.received_by,
            index: current,
          },
        },
      ],
    });
  }
}
