//add markers for history
export default async function addGeofences(mapRef, geofences) {
  if (!mapRef) return;

  function createGeoJSONCircle(center, radiusInMeters, points, geofence) {
    if (!points) points = 64;

    var coords = {
      latitude: center[1],
      longitude: center[0],
    };

    //convert meters to km
    var km = radiusInMeters / 1000;

    var ret = [];
    var distanceX = km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
    var distanceY = km / 110.574;

    var theta, x, y;
    for (var i = 0; i < points; i++) {
      theta = (i / points) * (2 * Math.PI);
      x = distanceX * Math.cos(theta);
      y = distanceY * Math.sin(theta);

      ret.push([coords.longitude + x, coords.latitude + y]);
    }
    ret.push(ret[0]);

    const feature = {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [ret],
      },
      properties: {
        name: geofence.name,
        radius: geofence.radius,
        longitude: geofence.longitude,
        latitude: geofence.latitude,
      },
    };

    return feature;
  }

  let features = {};

  if (geofences && geofences.length > 0) {
    features = {
      type: "FeatureCollection",
      features: geofences.map((item) => {
        return createGeoJSONCircle(
          [item.longitude, item.latitude],
          item.radius,
          64,
          item
        );
      }),
    };
  }

  if (!mapRef.getSource("tag-geofences")) {
    //add a source for tag history markers
    mapRef.addSource("tag-geofences", {
      type: "geojson",
      data: features,
    });

    //add a layer for tag history circles use fill to paint the circle
    mapRef.addLayer({
      id: "tag-geofences-layer",
      type: "fill",
      source: "tag-geofences",
      paint: {
        "fill-color": "#4ecca3",
        "fill-opacity": 0.5,
        //border 1px green
        "fill-outline-color": "#004d40",
      },
    });
  } else {
    mapRef.getSource("tag-geofences").setData(features);
  }
}
