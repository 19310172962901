import animalHash from "../components/AngryPurpleTiger";

export default async function updateHotspotsSource(
  hotspots,
  mapRef,
  history,
  index = 0
) {
  //convert to geojson points and update hotspot-source
  try {
    //hotspots in format: [{"channel":7,"frequency":868.5,"hold_time":0,"id":"112jLBX784xonNq9YnsgCaPeoE7WSGK4zsHNakQkSia7fJyR7Z68","lat":51.24638755312352,"long":-0.7433767535133884,"name":"loud-tawny-albatross","reported_at":1681133999785,"rssi":-126,"snr":-10,"spreading":"SF11BW125","status":"success"}]

    //convert hotspots to json
    let jsonHotspots = hotspots;

    //check if hotspots is a string or an object
    if (typeof hotspots === "string") {
      jsonHotspots = JSON.parse(hotspots);
    }

    let tagHotspotsSource = mapRef.getSource("hotspots-source");
    let tagHotspotsToCurrentLocationSource = mapRef.getSource(
      "hotspots-to-current-location"
    );

    if (!tagHotspotsSource) return;

    let currentLocation = {};

    if (history && history.length > 0) {
      // create currentLocation from history item with index
      currentLocation = {
        latitude: history[index].latitude,
        longitude: history[index].longitude,
      };
    }

    let features = [];
    let featuresToCurrentLocation = [];

    // use jsonHotspots
    jsonHotspots.forEach((item) => {
      if (item.long && item.lat) {
        if (item.long !== 0 && item.lat !== 0) {
          features.push({
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [item.long, item.lat],
            },
            properties: {
              id: item.id,
              rssi: item.rssi,
              name: animalHash(item.id),
              snr: item.snr,
            },
          });

          featuresToCurrentLocation.push({
            type: "Feature",
            geometry: {
              //lineString
              type: "LineString",
              coordinates: [
                [item.long, item.lat],
                [currentLocation.longitude, currentLocation.latitude],
              ],
            },
          });
        }
      }
    });

    let featureCollection2 = {
      type: "FeatureCollection",
      features: featuresToCurrentLocation,
    };

    tagHotspotsToCurrentLocationSource.setData(featureCollection2);

    //create featureCollection
    let featureCollection = {
      type: "FeatureCollection",
      features: features,
    };

    tagHotspotsSource.setData(featureCollection);
  } catch (error) {
    console.log(error);
    return;
  }
}
