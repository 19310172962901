import { Card, CardContent, Grid, Skeleton } from "@mui/material";

export default function SkeletonTable(props) {
  function CreateSkeletonTable() {
    const columns = [];
    for (let i = 0; i < props.columns; i++) {
      columns.push(
        <Grid item xs={12 / props.columns} key={"skeleton" + i}>
          <Skeleton height={40} width={"100%"} />
        </Grid>
      );
    }

    const rows = [];
    for (let i = 0; i < props.rows; i++) {
      rows.push(columns.map((column) => column));
    }

    return rows.map((row) => row);
  }

  function TableHeader() {
    const columns = [];
    for (let i = 0; i < props.columns; i++) {
      columns.push(
        <Grid item xs={12 / props.columns} key={i + "-header"}>
          <Skeleton height={40} width={"20%"} />
        </Grid>
      );
    }

    return columns.map((column) => column);
  }

  function TableFooter() {
    return (
      <>
        <Grid item xs={10}></Grid>
        <Grid item xs={2}>
          <Skeleton height={40} width={"100%"} />
        </Grid>
      </>
    );
  }

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <TableHeader />
          <CreateSkeletonTable />
          <TableFooter />
        </Grid>
      </CardContent>
    </Card>
  );
}
