import {
  Button,
  Grid,
  Input,
  Slider,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
} from "@mui/material";
import { latLngBounds } from "leaflet";
import React, { useMemo, useRef } from "react";
import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Circle, MapContainer, Marker, TileLayer } from "react-leaflet";
import * as L from "leaflet";
import { CONFIG } from "../../config";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddGeofence(props) {
  const [mapURL] = useState(
    props.mode === "dark"
      ? "https://{s}.tile.jawg.io/jawg-matrix/{z}/{x}/{y}{r}.png?access-token=" +
          CONFIG.jawg_access_token
      : "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  );
  const { handleClose, toggleGeofenceModal, addGeofenceModal, tracker } = props;
  const center = {
    lat: tracker?.last_location?.latitude || 0,
    lng: tracker?.last_location?.longitude || 0,
  };

  const [mapRef, setMapRef] = useState(null);
  const [radius, setRadius] = useState(20);
  const [geofenceName, setGeofenceName] = useState("New Geofence");

  const [position, setPosition] = useState(center);
  const markerRef = useRef(null);
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          setPosition(marker.getLatLng());
        }
      },
    }),
    []
  );

  const handleSliderChange = (event, newValue) => {
    setRadius(newValue);
  };

  const handleInputChange = (event) => {
    setRadius(event.target.value === "" ? "" : Number(event.target.value));
  };

  const handleBlur = () => {
    if (radius < 0) {
      setRadius(20);
    } else if (radius > 1000) {
      setRadius(1000);
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (mapRef) {
        if (props.tracker && props.tracker.last_location) {
          setPosition({
            lat: props.tracker.last_location.latitude,
            lng: props.tracker.last_location.longitude,
          });
          let markerBounds = latLngBounds([]);
          markerBounds.extend([
            props.tracker?.last_location?.latitude || 0,
            props.tracker?.last_location?.longitude || 0,
          ]);
          mapRef.fitBounds(markerBounds, {
            padding: [50, 50],
          });
        }
      }
      return () => (mounted = false);
    }
  }, [mapRef, props.tracker]);

  //  Create the Icon
  const LeafIcon = L.Icon.extend({
    options: {},
  });

  const greenIcon = new LeafIcon({
    iconUrl:
      "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  return (
    <Dialog
      onClose={handleClose}
      TransitionComponent={Transition}
      open={addGeofenceModal}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        Add Geofence for {props?.tracker?.name} - Drag and drop maker to
        reposition
      </DialogTitle>

      <DialogContent>
        <MapContainer
          className="mapContainer"
          zoom={13}
          ref={(ref) => {
            setMapRef(ref);
          }}
        >
          <TileLayer url={mapURL} />
          <Marker
            draggable={true}
            eventHandlers={eventHandlers}
            position={position}
            ref={markerRef}
            icon={greenIcon}
          />
          <Circle
            center={position}
            radius={radius}
            pathOptions={{ color: "#60bb9c" }}
          />
        </MapContainer>
        <Row className="mt-4">
          <Col md={6}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <TextField
                  label="Geofence Name"
                  value={geofenceName}
                  onChange={(e) => setGeofenceName(e.target.value)}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Col>
          <Col md={6}>
            <Typography id="input-slider" gutterBottom>
              Geofence Radius
            </Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <Slider
                  value={typeof radius === "number" ? radius : 20}
                  onChange={handleSliderChange}
                  aria-labelledby="input-slider"
                  valueLabelDisplay="auto"
                  step={10}
                  min={20}
                  max={1000}
                />
              </Grid>
              <Grid item>
                <Input
                  value={radius}
                  size="small"
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  inputProps={{
                    step: 10,
                    min: 20,
                    max: 1000,
                    type: "number",
                    "aria-labelledby": "input-slider",
                  }}
                />
                m
              </Grid>
            </Grid>
          </Col>
        </Row>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" onClick={toggleGeofenceModal}>
          Cancel
        </Button>
        <Button
          variant="outlined"
          onClick={() =>
            props.AddTrackerGeofence(geofenceName, radius, position)
          }
        >
          Add Geofence
        </Button>
      </DialogActions>
    </Dialog>
  );
}
